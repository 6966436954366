import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Message, ThreadMessage } from '../../../features/chat/interfaces/message.interface';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectDrivers, selectEmployees } from 'src/app/state/employees/employees.selectors';
import { EmployeeState } from 'src/app/customer/employees/enums/employee-state.enum';
import { State } from 'src/app/state/app.state';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MessageComponent implements OnInit, OnDestroy {
	@Input() message: Message | ThreadMessage;
	@Input() channelId: string | undefined;
	@Input() threadMessage: boolean;
	@Input() selfMessage: boolean;
	@Input() inDriverChat: boolean;

	subscription: Subscription = new Subscription();
	users: Array<Employee | Driver> = [];
	translating: boolean = false;
	translatedMessage: string | undefined;

	constructor(
		private translocoService: TranslocoService,
		private store: Store<State>,
		private cdr: ChangeDetectorRef,
		private chatService: ChatService,
	) {}

	ngOnInit(): void {
		this.getActiveEmployeesAndDrivers();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getMessageTime = (): string => moment(this.message.messageTime * 1000).locale('pl').format('LT')

	getName = (): string => {
		const deactivatedText = this.translocoService.translate('shared.label.deactivated');
		const replaceDeactivated = (name: string): string => {
			const regex = /\(deactivated\)/i;
			return name.replace(regex, `(${deactivatedText})`);
		};
		if (this.message.user.name === 'deactivated') {
			return deactivatedText;
		} else if (this.message.user.name.includes('deactivated')) {
			return replaceDeactivated(this.message.user.name);
		} else {
			return this.message.user.name;
		}
	}

	replaceUserIdsWithNames = (content: string): string => {
		return content.replace(/<@([a-f0-9\-]{36})>/g, (match, userId) => {
			(userId)
			const user = this.users.find(u => u.id === userId || u.driverId === userId);
			if (user) {
				return `<span class="user-name">@${user.name}</span>`;
			} else {
				return `<span class="user-name">@${this.translocoService.translate('shared.label.deactivated')}</span>`;
			}
		});
	}

	handleTranslateMessage = (): void => {
		if (!this.translating && !this.translatedMessage) {
			this.translating = true;
			this.cdr.detectChanges();
			const lang = this.translocoService.getActiveLang();
			this.subscription.add(
				this.chatService.translateMessage(this.message.content, lang).subscribe({
					next: (value) => {
						this.translatedMessage = value.text;
					},
					complete: () => {
						this.translating = false;
						this.cdr.detectChanges();
					},
				})
			)
		}
	}

	private getActiveEmployeesAndDrivers = (): void => {
		this.subscription.add(
			combineLatest([
				this.store.select((state) => selectEmployees(state)),
				this.store.select((state) => selectDrivers(state))
			]).pipe(
				map(([employees, drivers]) => {
					return [
						...employees.filter((employee) => employee.state === EmployeeState.active),
						...drivers.filter((driver) => driver.state === EmployeeState.active)
					];
				})
			).subscribe(combinedList => {
				this.users = combinedList;
				this.cdr.detectChanges();
			})
		);
	}
}
