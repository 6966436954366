<div class="vehicles-autocomplete-container">
	<form [formGroup]="vehicleFormGroup" class="vehicle-autocomplete-container">
		<div class="assigned-vehicle-container" *ngIf="assignedVehicle">
			<div class="assigned-vehicle" (click)="toggleVehicleAssignment(assignedVehicle)">
				<mat-icon>local_shipping}</mat-icon>
				<span>{{ assignedVehicle.name }}</span>
				<mat-icon>close</mat-icon>
			</div>
		</div>
		<input *ngIf="!assignedVehicle" #vehicleAutocompleteInput class="vehicle-select-input" formControlName="vehicle" [placeholder]="('shared.placeholder.' + (type === 'default' ? 'defaultVehicle' : 'trailerOrSemitrailer') | transloco) + type === 'default' ? ' *' : '' " />
		<ul *ngIf="(filteredVehicles | async)?.length && vehicleListVisible" class="predictions-container">
			<li class="prediction vehicle" (click)="toggleVehicleAssignment(vehicle)" *ngFor="let vehicle of filteredVehicles | async">
				{{ vehicle.name }} ({{ vehicle.registrationNumber }})
			</li>
		</ul>
	</form>
</div>
