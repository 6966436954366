import { createReducer, on } from '@ngrx/store';
import { Discovery } from 'src/app/features/chat/interfaces/discovery.interface';
import { ChannelMetadata } from 'src/app/features/chat/interfaces/channel-metadata.interface';
import { Message } from 'src/app/features/chat/interfaces/message.interface';
import { addChannelMetadata, addChatUser, addNewChannelParticipants, addUnreadMessage, removeChannelMetadata, removeChatUser, removeUnreadMessage, setChannelsMetadata, setChannelsParticipants, setChatUsers, setCurrentActiveSidebarChannel, setDiscovery, setNewMessage, setSelectedChannel, setUnreadMessages, updateChannelMetadata, updateChatUser } from './chat.actions';
import { User } from 'src/app/features/chat/interfaces/user.interface';

export interface ChatState {
	discovery: Discovery | null;
	channelsMetadata: ChannelMetadata[];
	channelsParticipants: Array<{ channelId: string, usersIds: string[] }>
	chatUsers: User[];
	selectedChannel: ChannelMetadata | null;
	newMessage: Message & { channelId: string } | null;
	unreadMessages: Array<Message & { channelId: string }>;
	currentActiveSidebarChannel: ChannelMetadata | null;
}

export const initialState: ChatState = {
	discovery: null,
	channelsMetadata: [],
	channelsParticipants: [],
	chatUsers: [],
	selectedChannel: null,
	newMessage: null,
	unreadMessages: [],
	currentActiveSidebarChannel: null
}

export const chatReducer = createReducer<ChatState>(
	initialState,
	on(setDiscovery, (state, { discovery }) => ({
		...state,
		discovery
	})),

	on(setChannelsMetadata, (state, { channelsMetadata }) => ({
		...state,
		channelsMetadata
	})),
	on(addChannelMetadata, (state, { channelMetadata }) => ({
		...state,
		channelsMetadata: [...state.channelsMetadata, channelMetadata]
	})),
	on(updateChannelMetadata, (state, { channelMetadata }) => ({
		...state,
		channelsMetadata: state.channelsMetadata.map(channel =>
			channel.channelId === channelMetadata.channelId ? channelMetadata : channel
		)
	})),
	on(removeChannelMetadata, (state, { channelId }) => ({
		...state,
		channelsMetadata: state.channelsMetadata.filter(channel => channel.channelId !== channelId)
	})),

	on(setCurrentActiveSidebarChannel, (state, { channel }) => ({
		...state,
		currentActiveSidebarChannel: channel
	})),

	on(setChannelsParticipants, (state, { channelsParticipants }) => ({
		...state,
		channelsParticipants: channelsParticipants.map(({ channelId, users }) => ({ channelId, usersIds: users }))
	})),
	on(addNewChannelParticipants, (state, { channelId, users }) => ({
		...state,
		channelsParticipants: [
			...state.channelsParticipants,
			{ channelId, usersIds: users }
		]
	})),

	on(setChatUsers, (state, { chatUsers }) => ({
		...state,
		chatUsers
	})),
	on(addChatUser, (state, { chatUser }) => ({
		...state,
		chatUsers: [...state.chatUsers, chatUser]
	})),
	on(updateChatUser, (state, { chatUser }) => ({
		...state,
		chatUsers: state.chatUsers.map(user =>
			user.userId === chatUser.userId ? chatUser : user
		)
	})),
	on(removeChatUser, (state, { userId }) => ({
		...state,
		chatUsers: state.chatUsers.filter(user => user.userId !== userId)
	})),

	on(setSelectedChannel, (state, { selectedChannel }) => ({
		...state,
		selectedChannel
	})),

	on(setNewMessage, (state, { newMessage }) => ({
		...state,
		newMessage
	})),

	on(setUnreadMessages, (state, { unreadMessages }) => ({
		...state,
		unreadMessages
	})),
	on(addUnreadMessage, (state, { unreadMessage }) => ({
		...state,
		unreadMessages:
			unreadMessage.channelId === state.selectedChannel?.channelId ||
			unreadMessage.channelId === state.currentActiveSidebarChannel?.channelId
				? state.unreadMessages : [...state.unreadMessages, unreadMessage]
	})),
	on(removeUnreadMessage, (state, { channelId }) => ({
		...state,
		unreadMessages: state.unreadMessages.filter(message => message.channelId !== channelId)
	})),
);