import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LngLatBounds } from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { Filter } from 'src/app/shared/enums/filter.enum';
import { Feedback } from 'src/app/shared/models/feedback.model';
import { LatLng } from 'src/app/shared/models/lat-lng.model';
import { MapBounds } from 'src/app/shared/models/map-bounds.model';
import { MapPointDetails } from 'src/app/shared/models/map-point-details.model';
import { MapPoint } from 'src/app/shared/models/map-point.model';
import { MapPointToVerify } from 'src/app/shared/models/map-point-to-verify.model';
import { ParkingType } from 'src/app/shared/enums/parking-type.enum';
import { SalesmanTask } from 'src/app/shared/interfaces/salesman-task.interface';
import { Salesman } from 'src/app/shared/models/salesman.model';

@Injectable({
	providedIn: 'root'
})
export class MapService {
	private locationDriverTimeLimit: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
	
	private salesmanTaskPreview: BehaviorSubject<SalesmanTask | null> = new BehaviorSubject<SalesmanTask | null>(null);
	public salesmanTaskPreviewObservable = this.salesmanTaskPreview.asObservable();

	private locateSalesman: BehaviorSubject<Salesman | null> = new BehaviorSubject<Salesman | null>(null);
	public locateSalesmanObservable = this.locateSalesman.asObservable();

	constructor(
		private http: HttpClient,
	) { }

	getMapPointsByBoundsAndFilters = (mapBounds: LngLatBounds, filters: Filter[]): Observable<MapPoint[]> => {
		if (this.locationDriverTimeLimit.value !== null) {
			return this.http.post<MapPoint[]>(ApiRoutes.map.points, {...this.mapBoundsMapper(mapBounds), filters, driverTimeLimit: this.locationDriverTimeLimit.value });
		}
		return this.http.post<MapPoint[]>(ApiRoutes.map.points, {...this.mapBoundsMapper(mapBounds), filters });
	}

	getMapPointById = (id: string): Observable<MapPointDetails> =>
		this.http.get<MapPointDetails>(ApiRoutes.map.pointById(id));

	getFeedbacksToVerify = (): Observable<Feedback[]> =>
		this.http.get<Feedback[]>(ApiRoutes.map.feedbacksToVerify);

	verifyFeedback = (id: string): Observable<any> => 
		this.http.put<any>(ApiRoutes.map.verifyFeedback(id), {});

	deleteFeedback = (id: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.map.deleteFeedback(id));

	getMapPointsToVerify = (): Observable<MapPointToVerify[]> =>
		this.http.get<MapPointToVerify[]>(ApiRoutes.map.mapPointsToVerify);

	getMapPointToVerifyDetails = (id: string): Observable<any> =>
		this.http.get<any>(ApiRoutes.map.mapPointToVerifyDetails(id));

	mergeMapPoints = (pointDetails: { [key: string]: any }): Observable<any> =>
		this.http.put<any>(ApiRoutes.map.mergePoints, pointDetails);

	separateMapPoints = (denyDuplicationPayload: { [key: string]: any }): Observable<any> =>
		this.http.put<any>(ApiRoutes.map.separatePoints, denyDuplicationPayload);

	setParkingFor = (availableForPayload: { id: string, availableFor: ParkingType[] }): Observable<any> =>
		this.http.put<any>(ApiRoutes.map.setParkingFor, availableForPayload)

	setLocationDriverTimeLimit = (val: number | null): void => {
		this.locationDriverTimeLimit.next(val);
	}

	deletePoint = (id: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.map.deletePoint(id));

	initAttributesMap = (): { [x: string]: string } => ({
		[Filter.GAS_STATION]: 'assets/icons/gas-station.svg',
		[Filter.FOOD]: 'assets/icons/food.svg',
		[Filter.WC]: 'assets/icons/wc.svg',
		[Filter.SHOWER]: 'assets/icons/shower.svg',
		[Filter.PARKING]: 'assets/icons/parking.svg',
		[Filter.ACCOMMODATION]: 'assets/icons/accommodation.svg',
		[Filter.ELECTRIC_CHARGING_STATION]: 'assets/icons/e-charging.svg',
		[Filter.RESTAURANT]: 'assets/icons/food.svg',
		[Filter.MOTORWAY_RESTAURANT]: 'assets/icons/food.svg',
		[Filter.MOTEL]: 'assets/icons/accommodation.svg',
		[Filter.SHOP]: 'assets/icons/shop.svg',
		[Filter.DEFIBRILLATOR]: 'assets/icons/medic.svg',
		[Filter.CASH_MACHINE]: 'assets/icons/atm.svg',
		[Filter.PLAYGROUND]: 'assets/icons/playground.svg',
		[Filter.TOLL_TERMINAL]: 'assets/icons/food.svg',
		[Filter.VENDING_MACHINE]: 'assets/icons/food.svg',
		[Filter.INTERNET_WIRELESS]: 'assets/icons/wifi.svg',
		[Filter.PICNIC_FACILITIES]: 'assets/icons/food.svg',
		[Filter.KIOSK]: 'assets/icons/shop.svg',
		[Filter.LAUNDRY]: 'assets/icons/laundry.svg',
		[Filter.CAR_WASH]: 'assets/icons/car-wash.svg',
		[Filter.WASTE_DISPOSAL]: 'assets/icons/waste.svg',
		[Filter.FRESH_WATER]: 'assets/icons/food.svg',
		[Filter.OVERNIGHT_ACCOMMODATION]: 'assets/icons/accommodation.svg',
		[Filter.REFUSE_BIN]: 'assets/icons/waste.svg',
		[Filter.FAX_MACHINE_OR_SERVICE]: 'assets/icons/repair.svg',
		[Filter.PUBLIC_PHONE]: 'assets/icons/food.svg',
		[Filter.DUMPING_STATION]: 'assets/icons/food.svg',
		[Filter.COPY_MACHINE_OR_SERVICE]: 'assets/icons/food.svg',
		[Filter.INTERNET_TERMINAL]: 'assets/icons/food.svg',
		[Filter.DOCSTOP]: 'assets/icons/food.svg',
		[Filter.OTHER]: 'assets/icons/food.svg',
		[Filter.TRUCK_REPAIR]: 'assets/icons/repair.svg',
		[Filter.TRUCK_WASH]: 'assets/icons/truck-wash.svg',
		[Filter.MOTORWAY_RESTAURANT_SMALL]: 'assets/icons/food.svg'
	});

	setSalesmanTaskMapPreview = (salesmanTask: SalesmanTask | null): void => {
		this.salesmanTaskPreview.next(salesmanTask);
	}

	setLocateSalesman = (salesman: Salesman | null): void => {
		this.locateSalesman.next(salesman);
	}

	private mapBoundsMapper = (lngLatBounds: LngLatBounds): MapBounds => new MapBounds(
			new LatLng(lngLatBounds.getNorthEast().lat, lngLatBounds.getNorthEast().lng),
			new LatLng(lngLatBounds.getSouthWest().lat, lngLatBounds.getSouthWest().lng)
		);
}
