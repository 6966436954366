import { createAction, props } from '@ngrx/store';
import { DriverPoint } from 'src/app/customer/map/interfaces/driver-point.interface';
import { Driver, DriverDetails } from 'src/app/customer/map/interfaces/driver.interface';
import { DriverHistoricCoord } from 'src/app/customer/map/interfaces/driver-historic-coord.interface';
import { LatLng } from 'src/app/shared/models/lat-lng.model';

export const setSelectedDriver = createAction(
	'[Map] Set selected driver',
	props<{ selectedDriver: DriverDetails | null }>()
)

export const setDriversPoints = createAction(
	'[Map] Set drivers points',
	props<{ driversPoints: DriverPoint[] }>()
)

export const updateDriverCoords = createAction(
	'[Map] Update driver coords',
	props<{ driverId: string, coords: LatLng, driver?: Driver }>()
)

export const setDriverHistoricCoords = createAction(
	'[Map] Set driver historic coords',
	props<{ driverHistoricCoords: DriverHistoricCoord[] }>()
)