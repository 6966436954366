import { createAction, props } from '@ngrx/store';
import { Board } from 'src/app/customer/board/interfaces/board.interface';
import { Attachment, Card, Comment, SimpleCard, SimpleTransportCard } from 'src/app/customer/board/interfaces/card.interface';
import { List } from 'src/app/customer/board/interfaces/list.interface';

export const setBoard = createAction(
	'[Tasks] Set board',
	props<{ board: Board | null }>()
)

export const setLists = createAction(
	'[Tasks] Set lists',
	props<{ lists: List[] }>()
)

export const setCards = createAction(
	'[Tasks] Set cards',
	props<{ cards: Array<{ listId: string, cards: Card[] }> }>()
)

export const setArchivedCards = createAction(
	'[Tasks] Set archived cards',
	props<{ cards: Card[] }>()
)

export const setSingleListCards = createAction(
	'[Tasks] Set single list cards',
	props<{ listId: string, cards: Card[] }>()
)

export const setSingleCard = createAction(
	'[Tasks] Set single card',
	props<{ listId: string, card: SimpleCard | SimpleTransportCard }>()
)

export const deleteList = createAction(
	'[Tasks] Delete list',
	props<{ listId: string }>()
)

export const deleteCard = createAction(
	'[Tasks] Delete card',
	props<{ listId: string, cardId: string }>()
)

export const archiveCard = createAction(
	'[Tasks] Archive card',
	props<{ listId: string, cardId: string }>()
)

export const addComment = createAction(
	'[Tasks] Add comment',
	props<{ cardId: string, listId: string, comment: Comment }>()
)

export const addAttachment = createAction(
	'[Tasks] Add attachment',
	props<{ cardId: string, listId: string, attachment: Attachment }>()
)

export const deleteAttachment = createAction(
	'[Tasks] Delete attachment',
	props<{ cardId: string, listId: string, attachmentId: string }>()
)