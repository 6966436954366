import { createReducer, on } from '@ngrx/store';
import { Board } from 'src/app/customer/board/interfaces/board.interface';
import { List } from 'src/app/customer/board/interfaces/list.interface';
import { addAttachment, addComment, archiveCard, deleteAttachment, deleteCard, deleteList, setArchivedCards, setBoard, setCards, setLists, setSingleCard, setSingleListCards } from './board.actions';
import { Card } from 'src/app/customer/board/interfaces/card.interface';

export interface BoardState {
	board: Board | null,
	lists: List[],
	cards: Array<{ listId: string, cards: Card[] }>;
	archive: Card[];
}

export const initialState: BoardState = {
	board: null,
	lists: [],
	cards: [],
	archive: []
}

export const boardReducer = createReducer<BoardState>(
	initialState,
	on(setBoard, (state, { board }): any => ({
		...state,
		board
	})),
	on(setLists, (state, { lists }): any => ({
		...state,
		lists,
	})),
	on(setCards, (state, { cards }): any => ({
		...state,
		cards,
	})),
	on(setArchivedCards, (state, { cards }): any => ({
		...state,
		archive: cards,
	})),
	on(setSingleListCards, (state, { listId, cards }) => ({
		...state,
		cards: state.cards.some(cardState => cardState.listId === listId)
			? state.cards.map(cardState =>
				cardState.listId === listId
					? { ...cardState, cards }
					: cardState
			)
			: [...state.cards, { listId, cards }]
	})),
	on(setSingleCard, (state, { listId, card }) => ({
		...state,
		cards: state.cards.map(cardState =>
			cardState.listId === listId
				? {
					...cardState,
					cards: cardState.cards.map(existingCard =>
						existingCard.cardId === card.cardId
							? { ...existingCard, ...card }
							: existingCard
					)
				}
				: cardState
		)
	})),
	on(deleteList, (state, { listId }): any => ({
		...state,
		lists: state.lists.filter(list => list.listId !== listId),
		cards: state.cards.filter(cardState => cardState.listId !== listId)
	})),
	on(deleteCard, (state, { listId, cardId }): any => ({
		...state,
		cards: state.cards.map(cardState =>
			cardState.listId === listId
				? {
					...cardState,
					cards: cardState.cards.filter(existingCard => existingCard.cardId !== cardId)
				}
				: cardState
		)
	})),
	on(archiveCard, (state, { listId, cardId }) => {
		let cardToArchive;
		const newState = {
			...state,
			cards: state.cards.map(cardState => {
				if (cardState.listId === listId) {
					return {
						...cardState,
						cards: cardState.cards.filter(existingCard => {
							if (existingCard.cardId === cardId) {
								cardToArchive = existingCard;
								return false;
							}
							return true;
						})
					};
				}
				return cardState;
			})
		};
		if (cardToArchive) {
			return {
				...newState,
				archive: [...state.archive, cardToArchive]
			};
		}
		return newState;
	}),
	on(addComment, (state, { cardId, listId, comment }) => ({
		...state,
		cards: state.cards.map(listCards =>
			listCards.listId === listId
				? {
					...listCards,
					cards: listCards.cards.map(card =>
						card.cardId === cardId
							? { ...card, comments: [...card.comments ?? [], comment] }
							: card
					)
				}
				: listCards
		)
	})),
	on(addAttachment, (state, { cardId, listId, attachment }): any => ({
		...state,
		cards: state.cards.map(listCards =>
			listCards.listId === listId
				? {
					...listCards,
					cards: listCards.cards.map(card =>
						card.cardId === cardId
							? { ...card, attachments: [...card.attachments ?? [], attachment] }
							: card
					)
				}
				: listCards
		)
	})),
	on(deleteAttachment, (state, { cardId, listId, attachmentId }): any => ({
		...state,
		cards: state.cards.map(listCards =>
			listCards.listId === listId
				? {
					...listCards,
					cards: listCards.cards.map(card =>
						card.cardId === cardId
							? {
								...card,
								attachments: card.attachments?.filter(attachment => attachment.attachmentId !== attachmentId)
							}
							: card
					)
				}
				: listCards
		)
	})),




)

