import { createAction, props } from '@ngrx/store';
import { UserData } from 'src/app/shared/interfaces/user-data.interface';
import { UserProfile } from 'src/app/shared/interfaces/user-profile.interface';

export const signOut = createAction('[Auth] Sign out')

export const signIn = createAction(
	'[Auth] Sign in',
	props<{ user: UserData }>()
)

export const setUser = createAction(
	'[Auth] Set user',
	props<{ user: UserData, userProfile: UserProfile }>()
)

export const setUserAvatar = createAction(
	'[Auth] Set user avatar',
	props<{ avatar: string }>()
)