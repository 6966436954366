import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRoutes } from '../configuration/api-routes';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(
		private auth: AuthService,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const urlsWithoutToken = [
			ApiRoutes.auth.login,
			ApiRoutes.auth.refreshToken,
			ApiRoutes.auth.signUp,
			ApiRoutes.auth.loginWithNewPassword,
			ApiRoutes.auth.resetPassword,
			'https://mercure'
		];
		if (!urlsWithoutToken.includes(req.url)) {
			const { token } = this.auth.getUserSessionCookies();
			const reqClone = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
			return next.handle(reqClone);
		}
		return next.handle(req);
	};
};
