import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { EmployeesState } from "./employees.reducer";

export const selectEmployeesState = (state: State) => state.employees;

export const selectEmployees = createSelector(
	selectEmployeesState,
	(state: EmployeesState) => state.employees
);

export const selectDrivers = createSelector(
	selectEmployeesState,
	(state: EmployeesState) => state.drivers
);