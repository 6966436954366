<div class="icon-wrapper">
	<button mat-mini-fab
		(click)="handleAction()"
		[color]="color"
		[ngClass]="color === 'basic' ? 'with-background' : ''"
	>
		<mat-icon>{{ icon }}</mat-icon>
	</button>
	<div class="indicator"
		*ngIf="indicator"
	>
	</div>
</div>