import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from './auth/guards/anonymous.guard';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
	},
	{
		path: 'auth',
		canActivate: [AnonymousGuard],
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: '**',
		redirectTo: 'auth'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
