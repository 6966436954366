import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private cookieService: CookieService,
		private translocoService: TranslocoService,
		private gtmService: GoogleTagManagerService,
	) {}

	ngOnInit(): void {
		this.setAppLanguage();
		this.gtmService.addGtmToDom();
	}

	private setAppLanguage = (): void => {
		const savedLang = this.cookieService.get('appLang');
		if (savedLang) {
			this.translocoService.setActiveLang(savedLang);
		}
	}
}
