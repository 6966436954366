import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	constructor(
		private _snackBar: MatSnackBar,
		private translocoService: TranslocoService
	) { }

	/**
	 * Default message: 'Coś poszło nie tak, spróbuj ponownie później'
	 * @param message (optional)
	 */
	open = (message: string = this.translocoService.translate('shared.error.somethingWentWrong')): void => {
		this._snackBar.open(message, 'Ok', {
			duration: 3000,
		})
	}
}
