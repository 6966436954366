import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
	@Input() icon: string;
	@Input() color: string = 'basic'
	@Input() indicator: boolean;

	@Output() action: EventEmitter<MouseEvent> = new EventEmitter();

	handleAction = (): void => {
		if (this.action) {
			this.action.emit();
		}
	}
}
