import { createReducer, on } from '@ngrx/store';
import { setUser, setUserAvatar, signIn, signOut } from './auth.actions';
import { UserData } from 'src/app/shared/interfaces/user-data.interface';
import { UserProfile } from 'src/app/shared/interfaces/user-profile.interface';

export interface AuthState {
	user: UserData | null,
	userProfile: UserProfile | null
}

export const initialState: AuthState = {
	user: null,
	userProfile: null
}

export const authReducer = createReducer<AuthState>(
	initialState,
	on(signIn, (state, { user }): any => ({
		...state,
		user
	})),
	on(setUser, (state, { user, userProfile }): any => ({
		...state,
		user,
		userProfile
	})),
	on(setUserAvatar, (state, { avatar }): any => ({
		...state,
		userProfile: {
			...state.userProfile,
			avatar
		}
	})),
	on(signOut, (): AuthState => initialState),
)