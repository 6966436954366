import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Card, SimpleTransportCard } from '../../../board/interfaces/card.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddNewCardModalComponent } from '../../../board/components/add-new-card-modal/add-new-card-modal.component';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, combineLatest, distinctUntilChanged, throttleTime } from 'rxjs';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { selectDrivers, selectEmployees } from 'src/app/state/employees/employees.selectors';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { BoardService } from '../../../board/services/board.service';
import { TaskStatus } from '../../../board/enums/task-status.enum';
import { archiveCard, deleteCard, setSingleCard } from 'src/app/state/board/board.actions';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CardType } from '../../../board/enums/card-type.enum';
import { selectClickedNotification } from 'src/app/state/notifications/notifications.selectors';
import { NotificationType } from '../../enums/notification-type.enum';
import { setClickedNotification } from 'src/app/state/notifications/notifications.actions';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'app-card-container',
	templateUrl: './card-container.component.html',
	styleUrls: ['./card-container.component.scss']
})
export class CardContainerComponent implements OnInit, OnDestroy {
	@Input() card: Card;
	@Input() listId: string;
	@Input() boardId: string;

	@Input() cardBadge: string | undefined;

	subscription: Subscription = new Subscription();
	assignedDriver: Driver | undefined;
	assignedEmployees: Employee[] = [];

	cardIdToHighlight: string | undefined;
	cardModalRef: MatDialogRef<AddNewCardModalComponent, any> | undefined

	constructor (
		private dialog: MatDialog,
		private transloco: TranslocoService,
		private store: Store<State>,
		private boardService: BoardService,
		private translocoService: TranslocoService,
	) {}

	ngOnInit(): void {
		this.getActiveEmployeesAndDrivers();
		this.checkClickedNotification();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	openAddNewCardModal = (): void => {
		if (!this.cardModalRef) {
			this.cardModalRef = this.dialog.open(AddNewCardModalComponent, {
				disableClose: true,
				data: {
					boardId: this.boardId,
					listId: this.listId,
					card: this.card
				}
			})
			this.cardModalRef.afterClosed().subscribe(() => this.cardModalRef = undefined)
			if (this.card) {
				this.subscription.add(
					this.boardService.getCard(this.card.cardId).subscribe({
						next: (value) => {
							this.store.dispatch(setSingleCard({ listId: this.listId, card: value }))
						},
					})
				)
			}
		}
	}

	getDeadline = (taskIndex: number): string => {
		if (this.isSimpleTransportCard(this.card)) {
			return 	moment(this.card.transportTasks[taskIndex].deadline).locale(this.transloco.getActiveLang()).format('LLL');
		}
		return '';
	}

	changeTaskStatus = (event: MouseEvent, taskIdx: number): void => {
		event.stopImmediatePropagation()
		if (this.isSimpleTransportCard(this.card)) {
			const status = this.card.transportTasks[taskIdx].status === TaskStatus.scheduled ? TaskStatus.done : TaskStatus.scheduled
			this.card.transportTasks[taskIdx].status = status;
			this.subscription.add(
				this.boardService.changeTaskStatus(this.card.cardId, this.card.transportTasks[taskIdx].taskId, status).subscribe()
			)
		}
	}

	deleteCard = (): void => {
		const dialogRef = this.openConfirmationDialog(this.translocoService.translate('dialog.deleteCard.info'));
		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this.subscription.add(
					this.boardService.deleteCard(this.card.cardId).subscribe(() => {
						this.store.dispatch(deleteCard({ listId: this.listId, cardId: this.card.cardId }))
					})
				)
			}
		})
	}

	archiveCard = (): void => {
		const dialogRef = this.openConfirmationDialog(this.translocoService.translate('dialog.archiveCard.info'));
		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this.subscription.add(
					this.boardService.archiveCard(this.card.cardId).subscribe((r) => {
						this.store.dispatch(archiveCard({ listId: this.listId, cardId: this.card.cardId }))
					})
				)
			}
		})
	}

	isSimpleTransportCard = (card: Card): card is SimpleTransportCard => {
		return card.type === CardType.simpleTransport;
	}

	private checkClickedNotification = (): void => {
		this.subscription.add(
			this.store.select(selectClickedNotification).pipe(
				distinctUntilChanged((prev, curr) => prev === curr),
			).subscribe({
				next: (notification) => {
					if (notification &&
						notification?.type !== NotificationType.userDeletedCard &&
						notification?.type !== NotificationType.userArchivedCard &&
						notification?.attributes[1].type === 'card' &&
						notification?.attributes[1].id === this.card.cardId
					) {
						setTimeout(() => {
							this.cardIdToHighlight = notification.attributes[1].id
							this.store.dispatch(setClickedNotification({ notificationId: undefined }));
							this.openAddNewCardModal()
							setTimeout(() => {
								this.cardIdToHighlight = undefined
							}, 1500);
						}, 500)
					} 
				},
			})
		)
	}

	private openConfirmationDialog = (info: string): MatDialogRef<ConfirmationDialogComponent, any> => {
		return this.dialog.open(ConfirmationDialogComponent, {
			autoFocus: false,
			data: { info }
		});
	}

	private getActiveEmployeesAndDrivers = (): void => {
		this.subscription.add(
			combineLatest([
				this.store.select((state) => selectEmployees(state)),
				this.store.select((state) => selectDrivers(state))
			]).pipe(

			).subscribe(([employees, drivers]) => {
				this.setAssignedDriver(drivers)
				this.setAssignedEmployees(employees)
			})
		);
	}

	private setAssignedDriver = (drivers: Driver[]): void => {
		this.assignedDriver = drivers.find((driver) => driver.driverId === this.card.assignedDriver);
	}

	private setAssignedEmployees = (employees: Employee[]): void => {
		this.assignedEmployees = employees.filter(employee => 
			this.card.assignedWorkers.some((employeeId) => employeeId === employee.id)
		);
	}
}
