<div class="message-container" [ngClass]="{ 'self-message': selfMessage, 'driver-chat': inDriverChat }">
	<app-avatar *ngIf="!selfMessage || !inDriverChat" [inChannel]="true" [src]="message.user.avatar"></app-avatar>
	<div class="message">
		<div class="message--row">
			<div class="col">
				<div class="name" *ngIf="!inDriverChat">{{ getName() }}</div>
				<div class="date">{{ getMessageTime() }}</div>
			</div>
			<div class="col">
				<div class="translate-btn"
					(click)="handleTranslateMessage()"
					[ngClass]="{ 'disabled': translatedMessage }"
				>
					<mat-icon>translate</mat-icon>
					<mat-spinner *ngIf="translating"></mat-spinner>
				</div>
			</div>
		</div>
		<div class="message-content" [innerHTML]="replaceUserIdsWithNames( message.content)"></div>
		<div class="message-content--translated" *ngIf="translatedMessage" [innerHTML]="replaceUserIdsWithNames(translatedMessage)"></div>
	</div>
</div>
<!-- 
<mat-menu #actions="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item (click)="openThread()">
			<mat-icon>comment</mat-icon>
			<span>{{ 'page.chat.channelPreview.addComment'| transloco }}</span>
		</button>
	</ng-template>
</mat-menu> -->