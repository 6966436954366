import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

	constructor(
		private translocoService: TranslocoService
	) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const locale = this.translocoService.getActiveLang();
		const reqClone = request.clone({ headers: request.headers.set('locale', locale) });
		return next.handle(reqClone);
	}
}
