import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import '@angular/common/locales/global/pl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { JwtInterceptor } from './shared/services/jwt-interceptor.service';
import { HttpErrorInterceptor } from './shared/services/http-error-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
import { LocaleInterceptor } from './shared/services/locale.interceptor';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './state/app.state';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientJsonpModule,
		HttpClientModule,
		NgxMapboxGLModule.withConfig({
			accessToken: environment.mapboxToken,
		}),
		SharedModule,
		TranslocoRootModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			}
		}),
		GoogleTagManagerModule.forRoot({
			id: environment.gtmId
		}),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'pl-PL' },
		CookieService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
