import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { AuthState } from "./auth.reducer";

export const selectAuthState = (state: State) => state.auth;

export const selectUser = createSelector(
	selectAuthState,
	(state: AuthState) => state.user
);

export const selectUserProfile = createSelector(
	selectAuthState,
	(state: AuthState) => state.userProfile
);