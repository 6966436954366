
<div class="modal-container">
	<h1 mat-dialog-title>{{ 'dialog.addNewChatChannel.header' | transloco}}</h1>
	<mat-dialog-content>
		<p>{{ 'dialog.addNewChatChannel.info' | transloco }}</p>
		<div class="new-channel-form">
			<app-input [formGroup]="newChannelFormGroup" controlName="name" [placeholder]="('shared.placeholder.channelName' | transloco) + ' *'"></app-input>
			<app-users-autocomplete [withActions]="true" [required]="true" (assignedUsersChange)="handleAssignedUserChange($event)"></app-users-autocomplete>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button [disabled]="loading" (click)="dialogRef.close()">{{ 'shared.button.cancel' | transloco }}</button>
		<button mat-raised-button color="primary" [disabled]="loading || !newChannelFormGroup.valid || assignedUsers.length === 0" (click)="handleAddNewChatChannel()">{{ 'shared.button.confirm' | transloco }}</button>
	</mat-dialog-actions>
</div>
