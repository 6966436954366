import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, interval, map, mergeMap, of, retry, switchMap, take, tap, throwError } from 'rxjs';
import { Discovery } from '../../features/chat/interfaces/discovery.interface';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { ChannelMetadata } from '../../features/chat/interfaces/channel-metadata.interface';
import { Message, Thread, ThreadMessage } from '../../features/chat/interfaces/message.interface';
import { NewMessage } from '../../features/chat/interfaces/new-message.interface';
import { User } from '../../features/chat/interfaces/user.interface';
import { Channel } from '../../features/chat/enums/channel.enum';
import { NewChannel } from '../../features/chat/interfaces/new-channel.interface';
import jwt_decode from 'jwt-decode';
import { MercureJWT } from '../../features/chat/interfaces/mercure-jwt.interface';
import { TechnicalMessage } from '../../features/chat/interfaces/technical-message.interface';
import { TechnicalEvent } from '../../features/chat/enums/technical-event.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/customer/shared/shared.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/auth/auth.selectors';
import { addChannelMetadata, addNewChannelParticipants, addUnreadMessage, removeChannelMetadata, removeUnreadMessage, setChannelsMetadata, setChannelsParticipants, setChatUsers, setDiscovery, setNewMessage, setSelectedChannel } from 'src/app/state/chat/chat.actions';
import { selectChannelsMetadata, selectDiscovery, selectSelectedChannel } from 'src/app/state/chat/chat.selectors';

@Injectable({
	providedIn: 'root'
})
export class ChatService {
	public abortEventSource: { [channelId: string]: AbortController } = {};

	constructor(
		private http: HttpClient,
		private auth: AuthService,
		private sharedService: SharedService,
		private store: Store<State>
	) {}

	initChatData = (): Observable<ChannelMetadata[]> => {
		return this.http.get<Discovery>(ApiRoutes.chat.discovery).pipe(
			switchMap(({ publicUrl, jwt, workspaces }) => {
				this.store.dispatch(setDiscovery({ discovery: { publicUrl, jwt, workspaces } }));
				this.store.dispatch(setChannelsParticipants({ channelsParticipants: workspaces[0].channels }))
				return this.initUsers(workspaces[0].workspaceId).pipe(
					switchMap(() => this.store.select(selectUser)),
					tap((user) => this.sharedService.createLiveDataEventSource(publicUrl, user?.id ?? '', jwt, 'user').subscribe()),
					tap(() => this.sharedService.createLiveDataEventSource(publicUrl, workspaces[0].workspaceId, jwt, 'organization').subscribe()),
					switchMap(() => {
						return this.http.get<ChannelMetadata[]>(ApiRoutes.chat.metadata(workspaces[0].workspaceId)).pipe(
							tap((channels) => {
								this.store.dispatch(setChannelsMetadata({ channelsMetadata: channels }));
								if (channels.length > 0) {
									this.selectChannel(channels[0]);
								}
							}),
							tap(() => this.listenTechnicalChannel(publicUrl, jwt))
						);
					})
				);
			})
		);
	}

	selectUser = (user: User): Observable<ChannelMetadata | void | null> => {
		return this.store.select(selectChannelsMetadata).pipe(
			take(1),
			switchMap((channels) => {
				const channelWithUserExist = channels.find((channel) => channel.type === Channel.direct && channel.userId === user.userId);
				if (channelWithUserExist) {
					return this.selectChannel(channelWithUserExist);
				}
				return this.createNewChannel(null, [user.userId], Channel.direct, user).pipe(
					tap((channel) => {
						if (channel?.channelId) {
							this.selectChannel(channel);
						}
					})
				);
			})
		);
	}

	getChannelMessages = (channelId: string, fromTime: number = 0): Observable<Message[]> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.get<Message[]>(ApiRoutes.chat.messages(discovery!.workspaces[0].workspaceId, channelId, 20, fromTime)))
		)
	
	createEventSource(channelId: string): Observable<any> {
		return this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery: Discovery | null) => {
				if (!discovery) {
					console.error('Discovery data is not available');
					return of([]);
				}
	
				const { publicUrl, workspaces, jwt } = discovery;
				const url = `${publicUrl}?topic=${publicUrl}/workspace/${workspaces[0].workspaceId}/channel/${channelId}`;
	
				return new Observable(observer => {
					this.abortEventSource[channelId] = new AbortController();
					const fetchData = () => {
						fetchEventSource(url, {
							signal: this.abortEventSource[channelId].signal,
							headers: {
								'Authorization': `Bearer ${jwt}`
							},
							onmessage: (event) => {
								const messageData: Message = JSON.parse(event.data);
								this.emitNewMessageToChannel(messageData, channelId);
								observer.next(messageData);
							},
							onerror: (err) => {
								console.error('Error while connecting streams:', err);
								observer.error(err);
							},
							keepalive: true,
						});
					};
					fetchData();
					return () => {
						observer.complete();
					};
				}).pipe(
					retry({
						delay: (error, retryCount) => {
							console.error(`Error while connecting streams, retry attempt # ${retryCount}:`, error);
							return interval(5000);
						}
					}),
					catchError(err => {
						console.error(err);
						return of([]);
					})
				);
			})
		);
	}

	createNewChannel = (channelName: null | string, participants: string[], type: Channel, user: User): Observable<ChannelMetadata | null> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.post<{ channelId: string }>(ApiRoutes.chat.newChannel, this.createNewChannelPayload(discovery!.workspaces[0].workspaceId, channelName, participants, type))),
			switchMap((channel) => {
				if (channel?.channelId) {
					const newChannel = { 
						channelId: channel.channelId,
						name: user.name,
						type: Channel.direct,
						avatar: user?.avatar ?? '',
						userId: user?.userId,
					}
					this.store.dispatch(addChannelMetadata({ channelMetadata: newChannel }));
					return of(newChannel).pipe( 
						tap((channel) => this.createEventSource(channel.channelId))
					);
				}
				return of(null)
			})
		);

	selectChannel = (channel: ChannelMetadata | null): Observable<void> => {
		return this.store.select((state) => selectSelectedChannel(state)).pipe(
			take(1),
			switchMap((currentChannel) => {
				if (currentChannel && channel && currentChannel.channelId === channel?.channelId) {
					return of(void 0);
				}

				if (channel) {
					this.store.dispatch(removeUnreadMessage({ channelId: channel.channelId }));
				}

				this.store.dispatch(setSelectedChannel({ selectedChannel: channel }));
				
				return of(void 0);
			})
		);
	}

	sendMessage = (message: string, channelId: string): Observable<Message> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.post<Message>(ApiRoutes.chat.newMessage, this.createNewMessagePayload(discovery!.workspaces[0].workspaceId, message, channelId)).pipe(
				tap((message) => {
					if (message && message?.content && message?.user) {
						this.store.dispatch(setNewMessage({ newMessage: { ...message, channelId } }))
					}
				})
			))
		)


	// findChannelByUserId = (userId: string): Observable<any> =>
	// 	this.http.get<any>(ApiRoutes.chat.channelByUserId(this.workspaces[0].workspaceId, userId))

	createNewPublicChannel = (channelName: string, participants: string[]): Observable<{ channelId: string }> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.post<{ channelId: string }>(ApiRoutes.chat.newChannel, {
				workspaceId: discovery!.workspaces[0].workspaceId,
				channelName,
				type: Channel.public,
				participants
			}).pipe(
				switchMap((channel) => this.store.select((state) => selectUser(state)).pipe(
					map(user => ({ userId: user?.id, channel: channel }))
				)),
				switchMap(({ channel, userId }) => {
					if (channel?.channelId) {
						return this.http.get<ChannelMetadata[]>(ApiRoutes.chat.metadata(discovery!.workspaces[0].workspaceId)).pipe(
							switchMap((channels) => {
								const newChannelMetadata = channels.find(c => c.channelId === channel.channelId);
								if (newChannelMetadata) {
									this.store.dispatch(addChannelMetadata({ channelMetadata: newChannelMetadata }));
									this.store.dispatch(addNewChannelParticipants({ channelId: channel.channelId, users: [...participants, userId!] }))
									this.selectChannel(newChannelMetadata);
									return of(channel).pipe(
										tap(() => this.createEventSource(channel.channelId))
									);
								} else {
									return throwError('New channel metadata not found');
								}
							})
						);
					}
					return of(channel);
				})
			))
		);
	
	deleteChannel = (channelId: string): Observable<any> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.delete<any>(ApiRoutes.chat.deleteChannel(discovery!.workspaces[0].workspaceId, channelId)).pipe(
				tap(() => {
					this.store.dispatch(removeChannelMetadata({ channelId }));
				}),
				switchMap(() => this.store.select(selectChannelsMetadata).pipe(take(1))),
				mergeMap((channels: ChannelMetadata[]) => {
					if (channels.length > 0) {
						this.selectChannel(channels[0]);
					} else {
						this.selectChannel(null);
					}
					return of(null);
				}),
				catchError((error) => {
					console.error('Error deleting channel:', error);
					return of(null);
				})
			))
		);

	saveParticipants = (type: 'join' | 'unjoin', participants: string[], channelId: string): Observable<any> =>
		this.store.select(selectDiscovery).pipe(
			take(1),
			switchMap((discovery) => this.http.post<any>(ApiRoutes.chat[type === 'join' ? 'addParticipants' : 'removeParticipants'], { workspaceId: discovery!.workspaces[0].workspaceId, channelId, users: participants }).pipe(
				tap(() => {
					const channel = discovery!.workspaces[0].channels.find(channel => channel.channelId === channelId);
					if (channel) {
						if (type === 'join') {
							channel.users = [...channel.users, ...participants];
						} else if (type === 'unjoin') {
							channel.users = channel.users.filter(userId => !participants.includes(userId));
						}
					}
				})
			))
		)

	translateMessage = (content: string, lang: string): Observable<{ text: string; detectedSourceLang: string }> =>
		this.http.post<{ text: string; detectedSourceLang: string }>(ApiRoutes.chat.translate, { text: content, lang }) 

	private emitNewMessageToChannel = (messageData: Message, channelId: string): void => {
		const userData = this.auth.getUserJwtData();
		if (messageData.user.userId === userData.id) {
			return;
		}

		this.playNewMessageSound();
		this.store.dispatch(setNewMessage({ newMessage: { ...messageData, channelId } }));
		this.store.dispatch(addUnreadMessage({ unreadMessage: { ...messageData, channelId } }));
	}

	private playNewMessageSound = (): void => {
		const audio = new Audio('../../../assets/audio/new-message.mp3');
		audio.volume = 1;
		audio.load();
		audio.addEventListener('canplaythrough', () => {
			audio.play();
		})
	}

	private listenTechnicalChannel = (publicUrl: string, jwt: string): Observable<any> => {
		const url = `${publicUrl}?topic=${publicUrl}/user/${this.decodeJWT(jwt)?.mercure.payload.userId}`;
		return new Observable(observer => {
			from(
				fetchEventSource(url, {
					headers: {
						'Authorization': `Bearer ${jwt}`
					},
					onmessage: (event) => {
						const messageData: TechnicalMessage = JSON.parse(event.data);
						if (messageData.event === TechnicalEvent.newDirect && messageData?.channel && messageData.channel.channelId) {
							this.store.dispatch(addChannelMetadata({ channelMetadata: messageData.channel }));
							this.createEventSource(messageData.channel.channelId);
						}
						observer.next(messageData)
					}
				})
			)
		});
	}

	private createNewMessagePayload = (workspaceId: string, message: string, channelId: string): NewMessage => ({
		workspaceId,
		channelId,
		content: message
	})

	private createNewChannelPayload = (workspaceId: string, channelName: null | string, participants: string[], type: Channel): NewChannel => ({
		workspaceId,
		channelName,
		participants,
		type
	})

	private initUsers = (workspaceId: string): Observable<void> => {
		return this.http.get<User[]>(ApiRoutes.chat.users(workspaceId)).pipe(
			switchMap((users) => {
				this.store.dispatch(setChatUsers({ chatUsers: users }));
				return of(void 0);
			})
		);
	}

	private decodeJWT = (token: string): MercureJWT | null => {
		try {
			return jwt_decode(token);
		} catch(Error) {
			return null;
		}
	}
}
