<div class="modal-container" [ngClass]="{ 'modal-container--resized': selectedCardType && selectedCardType !== _CardType.simpleCard }">
	<div class="column card-content-container">
		<div class="assigned-users-row row">
			<div class="row--content-container" [ngClass]="{ 'flexible-container': assignedUsers.length > 0}">
				<mat-icon>person</mat-icon>
				<app-users-autocomplete [disabled]="data?.archived ?? false" [inBoard]="true" [prefilledAssignedUsers]="assignedUsers" (assignedUsersChange)="handleAssignedUserChange($event)"></app-users-autocomplete>
			</div>
		</div>
		<form class="form" [formGroup]="formGroup">
			<div class="row">
				<div class="row--content-container">
					<mat-icon>format_list_bulleted</mat-icon>
					<mat-select (selectionChange)="initCardTypeFormGroup($event.value)" formControlName="type" class="card-type" [placeholder]="('shared.placeholder.selectCardType' | transloco) + ' *'">
						<mat-option *ngFor="let type of cardTypes" [value]="type">
							{{ 'shared.cardType.' + type | transloco }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="row">
				<div class="row--content-container">
					<mat-icon>title</mat-icon>
					<input formControlName="name" class="input" [placeholder]="('shared.placeholder.cardName' | transloco) + ' *'" />
				</div>
			</div>
			<div class="add-new-card-modal row">
				<div class="row--content-container flexibe-container">
					<mat-icon>description</mat-icon>
					<textarea formControlName="description" class="description" [placeholder]="('shared.placeholder.description' | transloco) + ' *'"></textarea>
				</div>
			</div>
		</form>
		<ng-container *ngIf="selectedCardType === _CardType.simpleTransport" [ngTemplateOutlet]="simpleTransport"></ng-container>
		<ng-container *ngIf="selectedCardType === _CardType.simpleCard" [ngTemplateOutlet]="simpleCard"></ng-container>
		<div class="comments-row row" *ngIf="this.data.card">
			<div class="row--content-container flexible-container">
				<mat-icon>comment</mat-icon>
				<div class="comments-container">
					<div class="comments-list" *ngIf="data?.card?.comments?.length">
						<div class="comment" *ngFor="let comment of data.card?.comments">
							<div class="col">
								<app-avatar [src]="getUserAvatar(comment.authorId)"></app-avatar>
								<h4 class="name">{{ getUserName(comment.authorId) }}</h4>
							</div>
							<div class="content">
								{{ comment.content }}
							</div>
							<div class="col date">
								{{ comment.createdAt | date }}
							</div>
						</div>
					</div>
					<div class="comment-input-container">
						<input [disabled]="data.archived ?? false" type="text" [(ngModel)]="newComment" placeholder="Komentarz" (keyup.enter)="addNewComment()"/>
						<div class="action-send" [ngClass]="{ 'disabled': addingComment || !newComment }" (click)="addNewComment()">
							<mat-spinner *ngIf="addingComment"></mat-spinner>
							<mat-icon>send</mat-icon>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon>attach_file</mat-icon>
				<div class="attached-files-container">
					<ng-container *ngIf="data.card?.attachments?.length && !attachmentsForNewCard.length">
						<div class="attachment" *ngFor="let attachment of data.card!.attachments" [matTooltip]="attachment.name">
							<ng-container [ngTemplateOutlet]="attachmentTemplate" [ngTemplateOutletContext]="{ attachment: attachment }"></ng-container>
						</div>
					</ng-container>
					<ng-container *ngIf="attachmentsForNewCard.length && !data.card?.attachments?.length">
						<div class="attachment" *ngFor="let attachment of attachmentsForNewCard" [matTooltip]="attachment.name">
							<ng-container [ngTemplateOutlet]="attachmentTemplate" [ngTemplateOutletContext]="{ attachment: attachment }"></ng-container>
						</div>
					</ng-container>
					<span class="no-attachments-label" [ngClass]="{ 'disabled-element': mode !== 'edit' }" *ngIf="!data.card?.attachments?.length">{{ 'shared.label.noAttachments' | transloco }}</span>
					<input type="file" accept=".jpg,.png,.pdf,.jpeg" style="display: none" #fileInput (change)="onFileSelected($event)">
				</div>
			</div>
		</div>
	</div>
	<div class="column card-actions-container">
		<button [matTooltip]="'shared.label.closeCard' | transloco" class="card-action-btn close-card" (click)="dialogRef.close()">
			<mat-icon>close</mat-icon>
		</button>
		<button [matTooltip]="('shared.label.addAttachment' | transloco) + ' (jpeg, jpg, png, pdf)'" *ngIf="!data?.archived" class="card-action-btn add-attachment" (click)="openFilePicker()">
			<mat-icon>attach_file</mat-icon>
		</button>
		<button [matTooltip]="'shared.label.saveCard' | transloco" *ngIf="mode !== 'preview' && !data?.archived" class="card-action-btn save-card" type="datetime-local" [ngClass]="{ 'disabled': !canSave()}" [disabled]="!formGroup.valid" (click)="saveCard()">
			<mat-icon>check</mat-icon>
		</button>
		<button [matTooltip]="'shared.label.editCard' | transloco" *ngIf="mode !== 'edit' && mode !== 'new' && !data?.archived" class="card-action-btn save-card" type="datetime-local" (click)="setEditMode()">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
</div>

<ng-template #simpleCard>
	<form class="form" [formGroup]="cardTypeFormGroup">
		<div class="row">
			<div class="row--content-container">
				<mat-icon>calendar_month</mat-icon>
				<input (click)="picker1.open()" placeholder="DD/MM/YYYY *" class="input date" [matDatepicker]="picker1" formControlName="from">
			</div>
			<div class="row--content-container">
				<mat-icon>start</mat-icon>
				<input (click)="picker2.open()" placeholder="DD/MM/YYYY *" class="input date" [matDatepicker]="picker2" formControlName="to">
			</div>
		</div>
	</form>
	<mat-datepicker #picker1></mat-datepicker>
	<mat-datepicker #picker2></mat-datepicker>
</ng-template>

<ng-template #simpleTransport>
	<form class="form" [formGroup]="cardTypeFormGroup">
		<div class="row">
			<div class="row--content-container task-category-container">
				<mat-icon>download</mat-icon>
				<p class="row--name">{{ 'shared.placeholder.pickup' | transloco }}</p>
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<div class="autocomplete-container">
					<input #pickupAutocompleteInput formControlName="pickupAddress" class="input" [placeholder]="('shared.placeholder.pickupAddress' | transloco) + ' *'" />
					<ul *ngIf="pickupPredictions.length > 0" class="predictions-container">
						<li class="prediction" (click)="selectPrediction(prediction, 'pickup')" *ngFor="let prediction of pickupPredictions">{{ prediction.description }}</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<input (click)="picker1.open()" placeholder="DD/MM/YYYY *" class="input date" [matDatepicker]="picker1" formControlName="pickupDate">
				<input (click)="timePicker1.open()" placeholder="12:00 *" [format]="24" class="input time" [ngxTimepicker]="timePicker1" [disableClick]="true" formControlName="pickupTime">
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<input formControlName="pickupNote" class="input" [placeholder]="'shared.placeholder.note' | transloco" />
			</div>
		</div>
		<div class="row">
			<div class="row--content-container task-category-container">
				<mat-icon>upload</mat-icon>
				<p class="row--name">{{ 'shared.placeholder.dropoff' | transloco }}</p>
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<div class="autocomplete-container">
					<input #dropoffAutocompleteInput formControlName="dropoffAddress" class="input" [placeholder]="('shared.placeholder.dropoffAddress' | transloco) + ' *'" />
					<ul *ngIf="dropoffPredictions.length > 0" class="predictions-container">
						<li (click)="selectPrediction(prediction, 'dropoff')" class="prediction" *ngFor="let prediction of dropoffPredictions">{{ prediction.description }}</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<input (click)="picker2.open()" placeholder="DD/MM/YYYY *" class="input date" [matDatepicker]="picker2" formControlName="dropoffDate">
				<input (click)="timePicker2.open()" placeholder="12:00 *" [format]="24" class="input time" [ngxTimepicker]="timePicker2" [disableClick]="true" formControlName="dropoffTime">
			</div>
		</div>
		<div class="row">
			<div class="row--content-container">
				<mat-icon></mat-icon>
				<input formControlName="dropoffNote" class="input" [placeholder]="'shared.placeholder.note' | transloco" />
			</div>
		</div>
	</form>
</ng-template>

<ng-template #visit></ng-template>
<ng-template #attachmentTemplate let-attachment="attachment">
	<div class="attachment-name" (click)="openAttachment(attachment.url)">
		<mat-icon>open_in_new</mat-icon>
		{{ attachment.name | truncate: 8 }}
	</div>
	<div class="attachment-delete" (click)="deleteAttachment(attachment.attachmentId)">
		<mat-icon>delete</mat-icon>
	</div>
</ng-template>

<mat-datepicker #picker1></mat-datepicker>
<mat-datepicker #picker2></mat-datepicker>
<ngx-material-timepicker #timePicker1></ngx-material-timepicker>
<ngx-material-timepicker #timePicker2></ngx-material-timepicker>