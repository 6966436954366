import { createAction, props } from "@ngrx/store";
import { Notification } from "src/app/customer/shared/interfaces/notification.interface";

export const setNotifications = createAction(
	'[Notifications] Set notifications',
	props<{ notifications: Notification[] }>()
);

export const addNotification = createAction(
	'[Notifications] Add notification',
	props<{ notification: Notification }>()
);

export const markNotificationsAsRead = createAction(
	'[Notifications] Mark notifications as read',
	props<{ notificationsIds: string[] }>()
);

export const setClickedNotification = createAction(
	'[Notifications] Set clicked notification',
	props<{ notificationId: string | undefined }>()
);