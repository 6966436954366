import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, map, of, startWith, tap } from 'rxjs';
import { EmployeeState } from 'src/app/customer/employees/enums/employee-state.enum';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { State } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/auth/auth.selectors';
import { selectDrivers, selectEmployees } from 'src/app/state/employees/employees.selectors';

@Component({
	selector: 'app-users-autocomplete',
	templateUrl: './users-autocomplete.component.html',
	styleUrls: ['./users-autocomplete.component.scss']
})
export class UsersAutocompleteComponent implements OnInit, AfterViewInit {
	@Input() prefilledAssignedUsers: (Employee | Driver)[];
	@Input() required: boolean = false;
	@Input() withActions: boolean = false;
	@Input() inBoard: boolean = false;
	@Input() driverAssignment: boolean = false;
	@Input() channelParticipantsEdit: boolean = false;
	@Input() disabled: boolean = false;

	@Output() assignedUsersChange: EventEmitter<{ assignedUsers: (Employee | Driver)[]; currentChange: Employee | Driver }> =
		new EventEmitter<{ assignedUsers: (Employee | Driver)[]; currentChange: Employee | Driver }>();

	@ViewChild('userAutocompleteInput') userAutocompleteInput!: ElementRef;

	subscription: Subscription = new Subscription();
	users: (Employee | Driver)[] = [];
	filteredUsers: Observable<(Employee | Driver)[]> = of([]);
	usersListVisible: boolean = false;
	assignedUsers: (Employee | Driver)[] = [];
	usersFormGroup: FormGroup;
	userControl: FormControl<any>;
	userAdminId: Observable<string | undefined>
	initFinished: boolean = false;

	constructor(
		private store: Store<State>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
	) {
		this.userAdminId = this.store.select((state) => selectUser(state)).pipe(map((data) => (data?.id)))
	}

	ngOnInit(): void {
		this.getActiveEmployeesAndDrivers();
		this.initNewChannelFormGroup();

		if (this.prefilledAssignedUsers) {
			this.assignedUsers = this.prefilledAssignedUsers;
		}
	}

	ngAfterViewInit(): void {
		this.initFinished = true;
	}

	@HostListener('document:click', ['$event'])
	listenForFocus(event: Event) {
		const clickedElement = event.target as HTMLElement;
		this.usersListVisible = !this.disabled &&
			this.userAutocompleteInput?.nativeElement.contains(clickedElement) ||
			clickedElement.className.includes('user-autocomplete-container') ||
			clickedElement.className.includes('assigned-users-container')
		this.cdr.detectChanges();
	}

	toggleUserAssignment = (user: Employee | Driver): void => {
		const assignedIdx = this.assignedUsers.findIndex((assignedUser) => user === assignedUser)
		if (assignedIdx >= 0) {
			this.assignedUsers.splice(assignedIdx, 1);
		} else {
			this.assignedUsers.push(user);
		}
		this.assignedUsersChange.emit({ assignedUsers: this.assignedUsers, currentChange: user });
		this.filteredUsers = this.handleControlChange();
	}

	addAll = (type: 'drivers' | 'employees'): void => {
		const all = this.users.filter((user) => type === 'drivers' ? user.driverId : user.id);
		all.forEach((user) => {
			const assignedIdx = this.assignedUsers.findIndex((assignedUser) => user === assignedUser)
			if (assignedIdx < 0) {
				this.assignedUsers.push(user);
			}
		})
		this.assignedUsersChange.emit({ assignedUsers: this.assignedUsers, currentChange: this.assignedUsers[0] });
	}

	private getActiveEmployeesAndDrivers = (): void => {
		this.subscription.add(
			combineLatest([
				this.driverAssignment ? of([]) : this.store.select((state) => selectEmployees(state)),
				this.store.select((state) => selectDrivers(state)),
				this.userAdminId,
			]).pipe(
				map(([employees, drivers, userAdminId]) => {
					return [
						...employees.filter((employee) => {
							if (this.inBoard) {
								return employee.state === EmployeeState.active
							}
							return employee.state === EmployeeState.active && employee.id !== userAdminId
						}),
						...drivers.filter((driver) => driver.state === EmployeeState.active)
					];
				}),
			).subscribe(combinedList => {
				this.users = combinedList;
			})
		);
	}

	private _filterUsers(value: string): (Employee | Driver)[] {
		const filterValue = value.toLowerCase();
		const isAnyDriverAssigned = this.assignedUsers.some(user => user.driverId !== undefined);

		if (this.inBoard) {
			return this.users.filter(user =>
				user.name.toLowerCase().includes(filterValue) &&
				!this.assignedUsers.includes(user) &&
				(!isAnyDriverAssigned || user.driverId === undefined)
			);
		}
		return this.users.filter(user =>
			user.name.toLowerCase().includes(filterValue) &&
			!this.assignedUsers.includes(user) 
		);
	}

	private initNewChannelFormGroup = (): void => {
		this.userControl = new FormControl();
		this.usersFormGroup = this.fb.group({
			users: this.userControl
		});

		this.filteredUsers = this.handleControlChange()

		if (this.disabled) {
			this.usersFormGroup.disable()
		}
	}

	private handleControlChange = (): Observable<(Employee | Driver)[]> => {
		return this.userControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filterUsers(value)),
			tap((v) => {
				return this.usersListVisible = v.length > 0 && !this.disabled && this.initFinished
			})
		);
	}

}
