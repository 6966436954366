import { createReducer, on } from '@ngrx/store';
import { Notification } from 'src/app/customer/shared/interfaces/notification.interface';
import { addNotification, markNotificationsAsRead, setClickedNotification, setNotifications } from './notifications.actions';

export interface NotificationsState {
	notifications: Notification[];
	clickedNotificationId: string | undefined;
}

export const initialState: NotificationsState = {
	notifications: [],
	clickedNotificationId: undefined
};

export const notificationsReducer = createReducer<NotificationsState>(
	initialState,
	on(setNotifications, (state, { notifications }) => ({
		...state,
		notifications
	})),
	on(addNotification, (state, { notification }) => ({
		...state,
		notifications: [...state.notifications, notification]
	})),
	on(markNotificationsAsRead, (state, { notificationsIds }) => ({
		...state,
		notifications: state.notifications.map(notification =>
			notificationsIds.includes(notification.id)
				? { ...notification, markedAsRead: true }
				: notification
		)
	})),
	on(setClickedNotification, (state, { notificationId }) => ({
		...state,
		clickedNotificationId: notificationId // Ustawiamy klikniętą notyfikację
	}))
);