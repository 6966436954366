import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Marker, Popup } from 'mapbox-gl';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { Salesman } from 'src/app/shared/models/salesman.model';
import { AddTaskComponent } from '../components/add-task/add-task.component';
import { SalesmanTask, Solution } from '../interfaces/salesman-task.interface';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class SalesmansService {

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
	) { }

	getSalesmans = (): Observable<Salesman[]> =>
		this.http.get<Salesman[]>(ApiRoutes.salesmans.getSalesmans);

	getSalesmanTasks = (id: string, date: string): Observable<Solution[]> =>
		this.http.get<Solution[]>(ApiRoutes.salesmans.getSalesmanTasks(date, id));

	createTask = (formData: FormData): Observable<SalesmanTask> =>
		this.http.post<SalesmanTask>(ApiRoutes.salesmans.createTask, formData);

	confirmTask = (id: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.salesmans.confirmTask(id), {});

	addSalesPopup = (mapZoom: number, marker: Marker, salesman?: Salesman, solution?: Solution): void => {
		const sizeClass = mapZoom > 5 || solution ? 'big' : 'small';
		const avatar = salesman ? 'assets/icons/user.png' : 'assets/images/company.png';
		const avatarClass = salesman ? 'half' : 'full'
		const html = `
			<div class="salesman-popup ${sizeClass}">
				<div class="img-container">
					<img class="image ${avatarClass}" src="${avatar}" />
				</div>
				${
					salesman ? 
						`<h2 class="name">${salesman?.name ?? `${salesman?.firstName} ${salesman?.lastName}`}</h2>`
							:
						`<h2 class="name">${solution?.name}</h2>`
				}
				${
					solution ? 
						`
						<div class="status">
							${ this.getStatusTranslaton(solution?.status) }
						</div>
						<div class="timeslot">
							${ moment(solution?.timeslot.start).locale('pl').format('LT') } - ${ moment(solution?.timeslot.end as Date).locale('pl').format('LT') }
						</div>
						`
						:
						''
				}

			</div>
		`;
		const popup = new Popup({
			offset: 10,
			closeButton: false,
			closeOnMove: false,
			closeOnClick: false,
		}).setHTML(html);
		marker.setPopup(popup).togglePopup();
	}

	getAddTaskDialog = (salesmans: Salesman[], selectedSalesmanId?: string): MatDialogRef<AddTaskComponent, any> => {
		return this.dialog.open(AddTaskComponent, {
			autoFocus: false,
			disableClose: true,
			data: { salesmans, selectedSalesmanId }
		});
	}

	getStatusTranslaton = (status: 'scheduled' | 'inProgress' | 'finished'): string => {
		const translationMap = {
			'scheduled': 'Zaplanowane',
			'inProgress': 'W trakcie',
			'finished': 'Zakończone'
		}
		return translationMap[status] ?? 'Brak danych'
	}
}
