import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState} from "./chat.reducer";

export const selectChatState = createFeatureSelector<ChatState>('chat');

export const selectDiscovery = createSelector(
	selectChatState,
	(state: ChatState) => state.discovery
);

export const selectChannelsMetadata = createSelector(
	selectChatState,
	(state: ChatState) => state.channelsMetadata
);

export const selectChatUsers = createSelector(
	selectChatState,
	(state: ChatState) => state.chatUsers
);

export const selectSelectedChannel = createSelector(
	selectChatState,
	(state: ChatState) => state.selectedChannel
);

export const selectNewMessage = createSelector(
	selectChatState,
	(state: ChatState) => state.newMessage
);

export const selectUnreadMessages = createSelector(
	selectChatState,
	(state: ChatState) => state.unreadMessages
);

export const selectChannelParticipantsIds = (channelId: string) => createSelector(
	selectChatState,
	(state: ChatState) => state.channelsParticipants.find((channel) => channel.channelId === channelId)!.usersIds || []
);