import { createAction, props } from '@ngrx/store';
import { ChannelMetadata } from 'src/app/features/chat/interfaces/channel-metadata.interface';
import { Discovery } from 'src/app/features/chat/interfaces/discovery.interface';
import { Message } from 'src/app/features/chat/interfaces/message.interface';
import { User } from 'src/app/features/chat/interfaces/user.interface';

export const setDiscovery = createAction(
	'[Chat] Set Discovery',
	props<{ discovery: Discovery | null }>()
)

export const setChannelsMetadata = createAction(
	'[Chat] Set Channels Metadata',
	props<{ channelsMetadata: ChannelMetadata[] }>()
);

export const addChannelMetadata = createAction(
	'[Chat] Add Channel Metadata',
	props<{ channelMetadata: ChannelMetadata }>()
);

export const updateChannelMetadata = createAction(
	'[Chat] Update Channel Metadata',
	props<{ channelMetadata: ChannelMetadata }>()
);

export const removeChannelMetadata = createAction(
	'[Chat] Remove Channel Metadata',
	props<{ channelId: string }>()
);

export const setCurrentActiveSidebarChannel = createAction(
	'[Chat] Set Current Active Sidebar Channel',
	props<{ channel: ChannelMetadata | null }>()
);

export const setChannelsParticipants = createAction(
	'[Chat] Set Channels Participants',
	props<{ channelsParticipants: Array<{ channelId: string, users: string[] }> }>()
);

export const addNewChannelParticipants = createAction(
	'[Chat] Add New Channel Participants',
	props<{ channelId: string, users: string[] }>()
);

export const setChatUsers = createAction(
	'[Chat] Set Chat Users',
	props<{ chatUsers: User[] }>()
)

export const addChatUser = createAction(
	'[Chat] Add Chat User',
	props<{ chatUser: User }>()
);

export const updateChatUser = createAction(
	'[Chat] Update Chat User',
	props<{ chatUser: User }>()
);

export const removeChatUser = createAction(
	'[Chat] Remove Chat User',
	props<{ userId: string }>()
);

export const setSelectedChannel = createAction(
	'[Chat] Set Selected Channel',
	props<{ selectedChannel: ChannelMetadata | null }>()
);

export const setNewMessage = createAction(
	'[Chat] Set New Message',
	props<{ newMessage: Message & { channelId: string } | null }>()
);

export const setUnreadMessages = createAction(
	'[Chat] Set Unread Messages',
	props<{ unreadMessages: Array<Message & { channelId: string }> }>()
);

export const addUnreadMessage = createAction(
	'[Chat] Add Unread Message',
	props<{ unreadMessage: Message & { channelId: string } }>()
);

export const removeUnreadMessage = createAction(
	'[Chat] Remove Unread Message',
	props<{ channelId: string }>()
);