import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-language-select',
	templateUrl: './language-select.component.html',
	styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
	currentLanguage: string = 'pl';
	availableLangs: string[] = [];

	constructor(
		private translocoService: TranslocoService, 
		private cookieService: CookieService,
	) {}

	ngOnInit(): void {
		this.currentLanguage = this.translocoService.getActiveLang();
		this.availableLangs = this.translocoService.getAvailableLangs() as string[];
	}

	setLanguage = (lang: MatSelectChange): void => {
		this.translocoService.setActiveLang(lang.value);
		this.cookieService.set('appLang', lang.value);
	}

	getCurrentLanguage = (): void => {
		const savedLang = this.cookieService.get('appLang');
		if (savedLang) {
			this.currentLanguage = savedLang;
		}
	}
}
