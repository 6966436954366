import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Salesman } from '../../models/salesman.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesmansService } from '../../services/salesmans.service';
import { Subscription } from 'rxjs';
import { SalesmanTask } from '../../interfaces/salesman-task.interface';
import { SnackbarService } from '../../services/snackbar.service';
import { Router } from '@angular/router';
import { MapService } from 'src/app/features/map/services/map.service';
@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {
	subscription: Subscription = new Subscription();
	salesmans: Salesman[] = [];
	selectedSalesman: Salesman;
	selectedSalesmanId: string;
	newTaskFormGroup: FormGroup;
	loading: boolean;
	error: boolean;
	salesmanTask: SalesmanTask | undefined;
	inputsDisabled: boolean;
	mapPreview: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {salesmans: Salesman[], selectedSalesmanId: string},
		private fb: FormBuilder,
		private salesmansService: SalesmansService,
		private dialog: MatDialog,
		private snackbar: SnackbarService,
		private router: Router,
		private map: MapService,
	) {
		this.salesmans = data.salesmans;
		this.selectedSalesman = data.salesmans.find(s => s.id === this.data?.selectedSalesmanId)!;
		this.selectedSalesmanId = this.selectedSalesman?.id
	}

	ngOnInit(): void {
		this.createFormGroup();
	}

	getSalesmanName = (salesman?: Salesman): string => {
		if (salesman) {
			return salesman?.name ?? `${salesman.firstName} ${salesman.lastName}`
		}
		return this.selectedSalesman?.name ?? `${this.selectedSalesman?.firstName} ${this.selectedSalesman?.lastName}`
	}

	createTask = (): void => {
		this.loading = true;
		this.error = false;

		const formData = new FormData();
		formData.append('salesmanId', this.newTaskFormGroup.value.salesman.id);
		formData.append('startDate', new Date(this.newTaskFormGroup.value.startDate).toISOString());
		formData.append('startHour', this.newTaskFormGroup.value.startHour);
		formData.append('file', this.newTaskFormGroup.value.file.files[0]);

		this.subscription.add(
			this.salesmansService.createTask(formData).subscribe({
				next: (value) => {
					this.salesmanTask = value;
					this.inputsDisabled = true;
					this.toggleDisabledInputs(true);
					this.loading = false;
				},
				error: (error) => {
					this.loading = false;
					this.error = true;
				},
			})
		)
	}

	confirmTask = (): void => {
		this.loading = true;
		this.subscription.add(
			this.salesmansService.confirmTask(this.salesmanTask!.importId).subscribe({
				complete: () => {
					this.loading = false;
					this.mapPreviewModalActions(true)
					this.dialog.closeAll();
					this.snackbar.open('Zadanie zostało poprawnie dodane')
				},
				error: (error) => {
					this.loading = false;
					this.error = true;
				},
			})
		)
	}

	showFullModal = (): void => {
		this.mapPreviewModalActions(true)
	}

	showOnMap = (): void => {
		const currentRoute =  this.router.url;
		if (currentRoute !== '/map') {
			this.router.navigateByUrl('/map').then(() => {
				this.mapPreviewModalActions();
			})
		} else {
			this.mapPreviewModalActions();
		}
	}

	editTaskDetails = (): void => {
		this.salesmanTask = undefined;
		this.inputsDisabled = false;
		this.toggleDisabledInputs(false);
	}

	toggleDisabledInputs = (disable: boolean): void => {
		for (const field in this.newTaskFormGroup.controls) {
			const control = this.newTaskFormGroup.get(field);
			if (disable) {
				control?.disable();
			} else {
				control?.enable();
			}
		}
	}
	
	private mapPreviewModalActions = (revert?: boolean): void => {
		const currentDialog = this.dialog.openDialogs[0];
		const backdrop = document.querySelector('.cdk-overlay-backdrop') as HTMLElement;

		if (revert) {
			currentDialog.updatePosition();
			backdrop.style.display = 'block'
			this.mapPreview = false;
			this.map.setSalesmanTaskMapPreview(null)
		} else {
			this.map.setSalesmanTaskMapPreview(this.salesmanTask!);
			currentDialog.updatePosition({ bottom: '0px' });
			backdrop.style.display = 'none'
			this.mapPreview = true;
		}
	}
	private createFormGroup = (): void => {
		this.newTaskFormGroup = this.fb.group({
			salesman: new FormControl({ value: '', disabled: this.inputsDisabled,}, Validators.required),
			startDate: new FormControl({ value: '', disabled: this.inputsDisabled}, Validators.required),
			startHour: new FormControl({ value: '', disabled: this.inputsDisabled}, Validators.required),
			file: new FormControl({ value: null, disabled: this.inputsDisabled}, Validators.required),
		})
	}
}
