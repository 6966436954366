
<div class="modal-container">
	<h1 mat-dialog-title>{{ 'dialog.manageChannelParticipants.header' | transloco}}</h1>
	<mat-dialog-content>
		<p>{{ 'dialog.manageChannelParticipants.info' | transloco }}</p>
		<div class="new-channel-form">
			<app-users-autocomplete [withActions]="true" [channelParticipantsEdit]="true" [required]="true" [prefilledAssignedUsers]="data.participants" (assignedUsersChange)="handleAssignedUserChange($event)"></app-users-autocomplete>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button [disabled]="loading" (click)="dialogRef.close()">{{ 'shared.button.cancel' | transloco }}</button>
		<button mat-raised-button color="primary" (click)="handleSaveParticipants()" [disabled]="loading || assignedUsers.length === 0">{{ 'shared.button.confirm' | transloco }}</button>
	</mat-dialog-actions>
</div>
