import { createSelector } from "@ngrx/store";
import { State } from "../app.state";
import { NotificationsState } from "./notifications.reducer";

export const selectNotificationsState = (state: State) => state.notifications;

export const selectAllNotifications = createSelector(
	selectNotificationsState,
	(state: NotificationsState) => state.notifications
);

export const selectNotificationById = (notificationId: string) => createSelector(
	selectNotificationsState,
	(state: NotificationsState) => state.notifications.find(notification => notification.id === notificationId)
);

export const selectClickedNotificationId = createSelector(
	selectNotificationsState,
	(state: NotificationsState) => state.clickedNotificationId
);

export const selectClickedNotification = createSelector(
	selectAllNotifications,
	selectClickedNotificationId,
	(notifications, clickedNotificationId) =>
		notifications.find(notification => notification.id === clickedNotificationId)
);