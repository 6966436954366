import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { Board } from '../interfaces/board.interface';
import { List } from '../interfaces/list.interface';
import { Attachment, Comment, SimpleCard, SimpleTransportCard } from '../interfaces/card.interface';
import { Card } from '../interfaces/card.interface';
import { TaskStatus } from '../enums/task-status.enum';

@Injectable({
	providedIn: 'root'
})
export class BoardService {
	constructor(
		private http: HttpClient,
	) { }

	getBoards = (): Observable<Board[]> =>
		this.http.get<Board[]>(ApiRoutes.customer.tasks.boards);

	getBoardLists = (boardId: string): Observable<List[]> =>
		this.http.get<List[]>(ApiRoutes.customer.tasks.getBoardLists(boardId))

	getBoardListCards = (boardId: string, listId: string): Observable<Card[]> =>
		this.http.get<Card[]>(ApiRoutes.customer.tasks.getBoardListCards(boardId, listId))

	getArchivedCards = (): Observable<Card[]> =>
		this.http.get<Card[]>(ApiRoutes.customer.tasks.archivedCards)

	createBoard = (name: string): Observable<{ boardId: string }> =>
		this.http.post<{ boardId: string }>(ApiRoutes.customer.tasks.boards, { name });

	createBoardList = (name: string, boardId: string): Observable<{ listId: string }> =>
		this.http.post<{ listId: string }>(ApiRoutes.customer.tasks.createBoardList(boardId), { name })

	editBoardList = (name: string, boardId: string, listId: string): Observable<{ listId: string }> =>
		this.http.put<{ listId: string }>(ApiRoutes.customer.tasks.editBoardList(boardId, listId), { name })

	deleteBoardList = (boardId: string, listId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.deleteBoardList(boardId, listId))

	createCard = (boardId: string, listId: string, newCard: SimpleTransportCard | SimpleCard): Observable<{ cardId: string }> =>
		this.http.post<{ cardId: string }>(ApiRoutes.customer.tasks.createBoardListCard(boardId, listId), { ...newCard })

	deleteCard = (cardId: string): Observable<{}> =>
		this.http.delete<{}>(ApiRoutes.customer.tasks.deleteCard(cardId))

	archiveCard = (cardId: string): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.archiveCard(cardId), {})

	editCard = (cardId: string, card: SimpleTransportCard | SimpleCard): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.editCard(cardId), { ...card })

	getCard = (cardId: string): Observable<Card> =>
		this.http.get<Card>(ApiRoutes.customer.tasks.getSingleCard(cardId))

	setListOrder = (boardId: string, listId: string, orderOfCards: string[]): Observable<{ cardId: string }> =>
		this.http.put<{ cardId: string }>(ApiRoutes.customer.tasks.setListOrder(boardId, listId), { orderOfCards })

	setColumnsOrder = (boardId: string, orderOfLists: string[]): Observable<{ cardId: string }> =>
		this.http.put<{ cardId: string }>(ApiRoutes.customer.tasks.setColumnsOrder(boardId), { orderOfLists })

	assignCardToList = (cardId: string, fromListId: string, toListId: string, orderOfCards: string[]): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.assignCardToList(cardId), { from: fromListId, to: toListId, orderOfCards })

	assignEmployeeToCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.toggleEmployeeToCardAssignment(cardId), { workerId: employeeId })

	unassignEmployeeFromCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.toggleEmployeeToCardAssignment(cardId), { body: { workerId: employeeId } })

	assignDriverToCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.toggleDriverToCardAssignment(cardId), { driverId: employeeId })

	unassignDriverFromCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.toggleDriverToCardAssignment(cardId), { body: { driverId: employeeId } })

	changeTaskStatus = (cardId: string, taskId: string, status: TaskStatus): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.changeTaskStatus(cardId, taskId), { status })

	addCardComment = (cardId: string, content: string): Observable<Comment> =>
		this.http.post<Comment>(ApiRoutes.customer.tasks.addComment(cardId), { content });

	addAttachment = (cardId: string, fileName: string, file: string): Observable<Attachment> =>
		this.http.post<Attachment>(ApiRoutes.customer.tasks.addAttachment(cardId), { attachment_name: fileName, attachment: file })

	deleteAttachment = (cardId: string, attachmentId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.deleteAttachment(cardId, attachmentId))
}
