import { Injectable } from '@angular/core';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Observable, from, startWith, take, tap, withLatestFrom } from 'rxjs';
import { LiveDataEvent } from '../map/interfaces/live-data-event.interface';
import { LiveDataEventType } from '../map/enums/live-data-event-type.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { setDriver, updateDriverSpeed } from 'src/app/state/employees/employees.actions';
import { updateDriverCoords } from 'src/app/state/map/map.actions';
import { selectDrivers } from 'src/app/state/employees/employees.selectors';
import { addNotification } from 'src/app/state/notifications/notifications.actions';
import { Notification } from './interfaces/notification.interface';
import { NotificationType } from './enums/notification-type.enum';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	private notificationTypes = [
		LiveDataEventType.userUpdatedCard,
		LiveDataEventType.userUnassignedToCard,
		LiveDataEventType.userAssignedToCard,
		LiveDataEventType.userDeletedCard,
		LiveDataEventType.userArchivedCard
	];

	constructor(
		private http: HttpClient,
		private store: Store<State>,
	) {}

	createLiveDataEventSource(publicUrl: string, id: string, jwt: string, channelFor: 'organization' | 'user'): Observable<any> {
		const url = `${publicUrl}?topic=${publicUrl}/${channelFor}/${id}`;
		return new Observable(observer => {
			from(
				fetchEventSource(url, {
					headers: {
						'Authorization': `Bearer ${jwt}`
					},
					onmessage: (event) => {
						const messageData: LiveDataEvent = JSON.parse(event.data);
						this.handleLiveDataEvent(messageData)
						observer.next(messageData)
					},
					onerror(err) {
						console.error(err)
					},
					keepalive: true,
				})
			)
		}).pipe(
			tap(),
			startWith(null)
		);
	}

	loadGoogleApi = (): Observable<any> => {
		return this.http.jsonp(
			`https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesApiKey}&libraries=places`,
			'callback'
		)
	}

	private handleLiveDataEvent = (event: LiveDataEvent): void => {
		if (event.type === LiveDataEventType.updateCoords) {
			this.store.select(state => selectDrivers(state)).pipe(
				take(1)
			).subscribe(drivers => {
				const driver = drivers.find(d => d.driverId === event.driverId);
				if (driver && event?.coords) {
					this.store.dispatch(updateDriverCoords({ driverId: event.driverId, coords: event.coords, driver }));
				}
			});
		}
		if (event.type === LiveDataEventType.usedInvitation) {
			this.store.dispatch(setDriver({ driver: event.profile!, invitationId: event.invitationId! }))
		}
		if (event.type === LiveDataEventType.updateSpeed) {
			const driverId = event.driverId;
			const value = event.speed!.value;
			const unit = event.speed!.unit;
			this.store.dispatch(updateDriverSpeed({ driverId, speedValue: value, speedUnit: unit }))
		}
		if (this.notificationTypes.includes(event.type)) {
			this.store.dispatch(addNotification({ notification: this.mapEventToNotification(event) }))
		}
	}

	private mapEventToNotification = (event: LiveDataEvent): Notification => {
		const notification: Notification = {
			id: event.id ?? '',
			attributes: event?.attributes ?? [],
			createdAt: event?.createdAt ?? '',
			markedAsRead: event?.markedAsRead ?? false,
			type: event.type as unknown as NotificationType,
		}
		return notification
	}
}
