import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter, from, map, mergeMap, of, startWith, take, tap, toArray } from 'rxjs';
import { VehicleStatus } from 'src/app/customer/fleet/enums/vehicle-status.enum';
import { VehicleType } from 'src/app/customer/fleet/enums/vehicle-type.enum';
import { Vehicle } from 'src/app/customer/fleet/interfaces/vehicle.interface';
import { State } from 'src/app/state/app.state';
import { isVehicleAssigned, selectAllVehicles } from 'src/app/state/fleet/fleet.selectors';

@Component({
	selector: 'app-vehicle-autocomplete',
	templateUrl: './vehicle-autocomplete.component.html',
	styleUrls: ['./vehicle-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleAutocompleteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() prefilledAssignedVehicle: Vehicle | undefined;
	@Input() prefilledAssignedTrailerOrSemitrailer: Vehicle | undefined;
	@Input() required: boolean = true;
	@Input() disabled: boolean = false;
	@Input() type: 'default' | 'trailer' | 'semitrailer' | undefined

	@Input() selectedTrailerOrSemitrailer: Vehicle | undefined;

	@Output() assignedVehicleChange: EventEmitter<Vehicle | undefined> = new EventEmitter();

	@ViewChild('vehicleAutocompleteInput') vehicleAutocompleteInput!: ElementRef;

	subscription: Subscription = new Subscription();
	vehicles: Vehicle[] = [];
	filteredVehicles: Observable<Vehicle[]> = of([]);
	vehicleListVisible: boolean = false;
	assignedVehicle: Vehicle | undefined;
	vehicleFormGroup: FormGroup;
	vehicleControl: FormControl<any>;
	initFinished: boolean = false;

	constructor(
		private store: Store<State>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		this.getActiveVehicles();
		this.initFormGroup();

		if (this.prefilledAssignedVehicle) {
			this.assignedVehicle = this.prefilledAssignedVehicle;
		}
		if (this.prefilledAssignedTrailerOrSemitrailer) {
			this.assignedVehicle = this.prefilledAssignedTrailerOrSemitrailer;
		}
	}

	ngAfterViewInit(): void {
		this.initFinished = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.['type']?.currentValue !== changes?.['type']?.previousValue ||
			changes?.['selectedTrailerOrSemitrailer']?.currentValue !== changes?.['selectedTrailerOrSemitrailer']?.previousValue
		) {
			this.getActiveVehicles();
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
	
	@HostListener('document:click', ['$event'])
	listenForFocus(event: Event) {
		const clickedElement = event.target as HTMLElement;
		this.vehicleListVisible = !this.disabled &&
			this.vehicleAutocompleteInput?.nativeElement.contains(clickedElement) ||
			clickedElement.className.includes('vehicle-autocomplete-container') ||
			clickedElement.className.includes('assigned-vehicle-container') &&
			this.assignedVehicle === undefined
		this.cdr.detectChanges();
	}

	toggleVehicleAssignment = (vehicle: Vehicle): void => {
		if (vehicle.id === this.assignedVehicle?.id) {
			this.assignedVehicle = undefined;
			this.assignedVehicleChange.emit(undefined)
		} else {
			this.assignedVehicleChange.emit(vehicle)
			this.assignedVehicle = vehicle
		}
	}

	private getActiveVehicles = (): void => {
		this.subscription.add(
			this.store.select(selectAllVehicles).pipe(
				mergeMap((vehicles) => 
					from(vehicles).pipe(
						mergeMap(vehicle => 
							this.store.select(isVehicleAssigned(vehicle.id)).pipe(
								take(1),
								map(isAssigned => ({ vehicle, isAssigned }))
							)
						),
						filter(({ vehicle, isAssigned }) => {
							const isActive = vehicle.status === VehicleStatus.active;
							if (isAssigned) {
								return false;
							}
							if (this.type === 'default') {
								if (this.selectedTrailerOrSemitrailer?.type) {
									if (this.selectedTrailerOrSemitrailer.type === VehicleType.semiTrailer) {
										return isActive && vehicle.type !== VehicleType.trailer && vehicle.type !== VehicleType.semiTrailer && vehicle.type === VehicleType.tractor;
									} else if (this.selectedTrailerOrSemitrailer.type === VehicleType.trailer) {
										return isActive && vehicle.type !== VehicleType.trailer && vehicle.type !== VehicleType.semiTrailer && vehicle.type !== VehicleType.tractor;
									}
									return isActive && vehicle.type !== VehicleType.trailer && vehicle.type !== VehicleType.semiTrailer;
								}
								return isActive && vehicle.type !== VehicleType.trailer && vehicle.type !== VehicleType.semiTrailer;
							} else if (this.type === 'trailer') {
								return isActive && vehicle.type === VehicleType.trailer;
							} else if (this.type === 'semitrailer') {
								return isActive && vehicle.type === VehicleType.semiTrailer;
							}
							return isActive && (vehicle.type === VehicleType.trailer || vehicle.type === VehicleType.semiTrailer);
						}),
						map(({ vehicle, isAssigned }) => vehicle),
						toArray()
					)
				)
			).subscribe(vehicles => {
				this.vehicles = vehicles;
			})
		);
	}

	private _filterVehicles(value: string): Vehicle[] {
		const filterValue = value.toLowerCase();

		return this.vehicles.filter(vehicle =>
			vehicle.name.toLowerCase().includes(filterValue) &&
			this.assignedVehicle?.name !== vehicle.name
		);
	}

	private initFormGroup = (): void => {
		this.vehicleControl = new FormControl();
		this.vehicleFormGroup = this.fb.group({
			vehicle: this.vehicleControl
		});

		this.filteredVehicles = this.handleControlChange()

		if (this.disabled) {
			this.vehicleFormGroup.disable()
		}
	}

	private handleControlChange = (): Observable<Vehicle[]> => {
		return this.vehicleControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filterVehicles(value)),
			tap((v) => {
				return this.vehicleListVisible = v.length > 0 && !this.disabled && this.initFinished
			})
		);
	}

}
