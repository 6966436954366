import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'trimVehicleId'
})
export class TrimVehicleIdPipe implements PipeTransform {

	transform(value: string): string {
		if (value && value.includes('vehicle')) {
			return value.split('?')[0];
		}
		return value;
	}
}