<div class="add-task-dialog">
	<ng-container *ngIf="!mapPreview">
		<h1 class="header" *ngIf="selectedSalesman; else addTaskTitle">
			Dodaj zadanie dla: 
			<span class="name">{{ getSalesmanName() }}</span>
		</h1>
		<form [formGroup]="newTaskFormGroup">
			<mat-form-field>
				<mat-label>Wybrany handlowiec</mat-label>
				<mat-select [(value)]="selectedSalesman" formControlName="salesman">
				  <mat-option *ngFor="let salesman of salesmans" [value]="salesman">
					{{ getSalesmanName(salesman) }}
				  </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Wybierz datę zadania</mat-label>
				<input (click)="picker.open()" matInput [matDatepicker]="picker" formControlName="startDate">
				<mat-hint>DD/MM/YYYY</mat-hint>
				<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Wybierz godzinę rozpoczęcia</mat-label>
				<input (click)="timePicker.open()" [format]="24" matInput [ngxTimepicker]="timePicker" [disableClick]="true" formControlName="startHour">
				<ngx-material-timepicker-toggle [for]="timePicker"></ngx-material-timepicker-toggle>
				<ngx-material-timepicker #timePicker></ngx-material-timepicker>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Dodaj plik CSV</mat-label>
				<ngx-mat-file-input required [accept]="'.csv'" #fileInput formControlName="file"></ngx-mat-file-input>
				<button [disabled]="inputsDisabled" mat-icon-button matSuffix *ngIf="!fileInput.empty" (click)="fileInput.clear($event)">
					<mat-icon>clear</mat-icon>
				</button>
				<button mat-icon-button class="folder-button" matSuffix *ngIf="fileInput.empty">
					<mat-icon>folder</mat-icon>
				</button>
			</mat-form-field>
		</form>
		<div class="summary-container">
			<ng-container *ngIf="salesmanTask">
				<h2 class="summary-header">Podsumowanie:</h2>
				<div class="summary-details">
					<div class="detail">
						Całkowity dystans: {{ salesmanTask.summary.distance }}
					</div>
					<div class="detail">
						Całkowity czas jazdy: {{ salesmanTask.summary.time }}
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="error">
				<h2 class="error-header">Błąd:</h2>
				<div class="error-info">
					<div class="detail">
						Wystąpił błąd! Sprawdź poprawność danych i pliku CSV lub spróbuj ponownie później
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<div class="btns-container">
		<mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
		<button mat-raised-button mat-dialog-close>Anuluj</button>
		<button *ngIf="!salesmanTask" mat-raised-button color="primary" [disabled]="!newTaskFormGroup.valid" (click)="createTask()">Wygeneruj zadanie</button>
		<button *ngIf="salesmanTask && inputsDisabled && !mapPreview" mat-raised-button color="accent" [disabled]="!salesmanTask" (click)="editTaskDetails()">Edytuj</button>
		<button *ngIf="salesmanTask && !mapPreview" mat-raised-button color="primary" [disabled]="!salesmanTask" (click)="showOnMap()">Zobacz na mapie</button>
		<button *ngIf="mapPreview" mat-raised-button color="primary" (click)="showFullModal()">Wróć</button>
		<button *ngIf="salesmanTask" mat-raised-button color="primary" [disabled]="!salesmanTask" (click)="confirmTask()">Zatwierdź</button>
	</div>
</div>

<ng-template #addTaskTitle>
	<h1 class="header">
		Dodaj zadanie
	</h1>
</ng-template>