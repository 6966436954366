import { createReducer, on } from '@ngrx/store';
import { setDriverHistoricCoords, setDriversPoints, setSelectedDriver, updateDriverCoords } from './map.actions';
import { DriverDetails } from 'src/app/customer/map/interfaces/driver.interface';
import { DriverPoint } from 'src/app/customer/map/interfaces/driver-point.interface';
import { DriverStatus } from 'src/app/customer/map/enums/driver-status.enum';
import { DriverHistoricCoord } from 'src/app/customer/map/interfaces/driver-historic-coord.interface';

export interface MapState {
	selectedDriver: DriverDetails | null;
	driversPoints: DriverPoint[];
	driverHistoricCoords: DriverHistoricCoord[];
}

export const initialState: MapState = {
	selectedDriver: null,
	driversPoints: [],
	driverHistoricCoords: [],
}

export const mapReducer = createReducer<MapState>(
	initialState,
	on(setSelectedDriver, (state, { selectedDriver }): any => ({
		...state,
		selectedDriver
	})),
	on(updateDriverCoords, (state, { driverId, coords, driver }) => {
		const driverPointIdx = state.driversPoints.findIndex((d) => d.driverId === driverId);
		const driversPoints = state.driversPoints;

		if (driverPointIdx >= 0) {
			driversPoints[driverPointIdx].coords = {
				latitude: coords.latitude,
				longitude: coords.longitude
			};
		} else if (driver) {
			driversPoints.push(
				{
					avatar: driver?.avatar ?? '',
					coords,
					driverId: driver.driverId,
					name: driver?.name ?? '',
					status: DriverStatus.PAUSE,
					timestamp: new Date(),
					type: 'driver'
				}
			)
		}

		return {
			...state,
			driversPoints: [...driversPoints],
		};
	}),
	on(setDriversPoints, (state, { driversPoints }): any => ({
		...state,
		driversPoints
	})),
	on(setDriverHistoricCoords, (state, { driverHistoricCoords }): any => ({
		...state,
		driverHistoricCoords
	})),
)