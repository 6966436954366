/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiRoutes } from '../configuration/api-routes';
import { SnackbarService } from './snackbar.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService,
		private snackbarService: SnackbarService,
		private translocoService: TranslocoService
	) {};

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const urlsToSignOut = [
			ApiRoutes.auth.refreshToken,
		];
		return next.handle(request).pipe(
			catchError(error => {
				if (error.status === 400 && urlsToSignOut.includes(request.url)) {
					this.authService.logout();
					this.snackbarService.open(this.translocoService.translate('shared.error.sessionExpired'));
				} else if (error.status === 401) {
					return this.handleRefreshToken(request, next)
				} else if (error?.error?.code === 4002 || error?.error?.code === 4019) {
					this.snackbarService.open(this.translocoService.translate('shared.error.wrongEmailOrPassword'));
				} else if (error?.error?.code === 4018) {
					this.snackbarService.open(this.translocoService.translate('shared.error.accountIsDisabled'));
				} else if (error?.error?.code === 4020) {
					this.snackbarService.open(this.translocoService.translate('shared.error.accountIsNotActive'));
				} else if (error?.error?.code === 4021) {
					this.snackbarService.open(this.translocoService.translate('shared.error.activationUrlExpired'));
				} else if (error?.error?.code === 4022) {
					this.snackbarService.open(this.translocoService.translate('shared.error.employeeAlreadyInCompany'));
				} else if (error?.error?.code === 4023 || error?.error?.code === 4026) {

				} else if (error?.error?.code === 4024) {
					this.snackbarService.open(this.translocoService.translate('shared.error.employeeAlreadyInCompany'));
				} else if (error?.error?.code === 4031) {
					this.snackbarService.open(this.translocoService.translate('shared.error.driverAssignedToOtherVehicle'));
				} else {
					this.snackbarService.open();
				} 
				return throwError(error);
			})
		);
	}

	private handleRefreshToken = (request: HttpRequest<any>, next: HttpHandler) => this.authService.refreshToken().pipe(
		switchMap(({ access_token }) => {
			const updatedReq = request.clone({
				headers: request.headers.set('Authorization', 'Bearer ' + access_token),
			});
			return next.handle(updatedReq);
		})
	);
};
