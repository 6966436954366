import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { ChatService } from '../../services/chat.service';

@Component({
	selector: 'app-manage-chat-participants',
	templateUrl: './manage-chat-participants.component.html',
	styleUrls: ['./manage-chat-participants.component.scss']
})
export class ManageChatParticipantsComponent implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();
	loading: boolean = false;
	originalAssignedUsers: (Employee | Driver)[] = [];
	assignedUsers: (Employee | Driver)[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { participants: Array<Employee | Driver>, channelId: string },
		public dialogRef: MatDialogRef<ManageChatParticipantsComponent>,
		private chatService: ChatService,
	) { }

	ngOnInit(): void {
		this.originalAssignedUsers = [...this.data.participants];	
		this.assignedUsers = this.data.participants;	
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	handleSaveParticipants = (): void => {
		this.loading = true;
		const differences = this.findUserDifferences();
		const requests: any = [];

		if (differences.addedUserIds.length > 0) {
			requests.push(
				this.chatService.saveParticipants('join', differences.addedUserIds, this.data.channelId).pipe(
					catchError(err => {
						console.error('Error adding users:', err);
						return of(null);
					})
				)
			);
		}
		if (differences.removedUserIds.length > 0) {
			requests.push(
				this.chatService.saveParticipants('unjoin', differences.removedUserIds, this.data.channelId).pipe(
					catchError(err => {
						console.error('Error removing users:', err);
						return of(null);
					})
				)
			);
		}

		if (requests.length > 0) {
			this.subscription.add(
				forkJoin(requests).subscribe({
					next: () => {
						this.loading = false;
						this.dialogRef.close();
					},
					error: (err) => {
						console.error('Error in requests:', err);
						this.loading = false;
					}
				})
			);
		} else {
			this.loading = false;
			this.dialogRef.close();
		}
	}

	handleAssignedUserChange = (e: { assignedUsers: (Employee | Driver)[]; currentChange: Employee | Driver }): void => {
		this.assignedUsers = e.assignedUsers;
	}

	private findUserDifferences = (): { removedUserIds: string[]; addedUserIds: string[] } => {
		const getId = (user: Employee | Driver): string | undefined => {
			return user?.id ?? user?.driverId;
		}
	
		const removedUserIds = this.originalAssignedUsers
			.filter(originalUser => !this.assignedUsers.some(currentUser => getId(currentUser) === getId(originalUser)))
			.map(user => getId(user))
			.filter((userId): userId is string => userId !== undefined);
	
		const addedUserIds = this.assignedUsers
			.filter(currentUser => !this.originalAssignedUsers.some(originalUser => getId(originalUser) === getId(currentUser)))
			.map(user => getId(user))
			.filter((userId): userId is string => userId !== undefined);
	
		return { removedUserIds, addedUserIds };
	}
}
