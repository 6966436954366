export const environment = {
	production: false,
	apiUrl: 'https://api.test.truckdrive.io',
	// toggle apiUrls comments to change dev/prod env
	// apiUrl: 'https://api.truckdrive.io',
	mapboxToken: 'pk.eyJ1IjoiaHVibG9jayIsImEiOiJja2wydm5meWgxaDVyMnJxb25ycTg4b2Z0In0.WT-0N3XE7n8jmVzWggSZAg',
	stripePublishableKey: {
		pl: 'pk_test_51IlST9AyOUfYOIOBC3v1fiJvA9wsxXW4Ntr9QMHQLp42ziQuljOes9Kqr3AhRxNnhZzhK2zID5gpR8dfGa7SYQsL00JK3DeAww',
		en: 'pk_test_51IlST9AyOUfYOIOBC3v1fiJvA9wsxXW4Ntr9QMHQLp42ziQuljOes9Kqr3AhRxNnhZzhK2zID5gpR8dfGa7SYQsL00JK3DeAww',
		de: 'pk_test_51IlST9AyOUfYOIOBC3v1fiJvA9wsxXW4Ntr9QMHQLp42ziQuljOes9Kqr3AhRxNnhZzhK2zID5gpR8dfGa7SYQsL00JK3DeAww'
	},
	stripePricingTableId: {
		pl: 'prctbl_1P5qxKAyOUfYOIOBfuKU9tb5',
		en: 'prctbl_1P5qxKAyOUfYOIOBfuKU9tb5',
		de: 'prctbl_1P5qxKAyOUfYOIOBfuKU9tb5'
	},
	tomTomToken: 'M76IGG6SXVqalTtKYk2uTX88iecJeAjx',
	googlePlacesApiKey: 'AIzaSyDmsFUx8D1PmsdAEVqcSi-F2YfBbcC0SWc',
	gtmId: 'GTM-WFW6SKV5',
};