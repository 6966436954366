import { Component, Input } from '@angular/core';
import { Channel } from '../../../features/chat/enums/channel.enum';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
	@Input() src: string;
	@Input() type: Channel;
	@Input() inChannel: boolean;
	@Input() isVisible: boolean = true;

	getSrc = (): string => {
		const emptyAvatarMap = {
			[Channel.direct]: 'assets/icons/user.png',
			[Channel.private]: 'assets/icons/users.png',
			[Channel.public]: 'assets/icons/users.png',
		}
		if (this.src) {
			return this.src;
		}
		if (this.inChannel) {
			return emptyAvatarMap[Channel.direct];
		}
		return emptyAvatarMap[this.type];
	}
}
