/* eslint-disable @typescript-eslint/naming-convention */
export enum Filter {
	GAS_STATION = 'GAS_STATION',
	FOOD = 'FOOD',
	WC = 'WC',
	SHOWER = 'SHOWER',
	PARKING = 'PARKING',
	ACCOMMODATION = 'ACCOMMODATION',
	ELECTRIC_CHARGING_STATION = 'ELECTRIC_CHARGING_STATION',
	RESTAURANT = 'RESTAURANT',
	MOTORWAY_RESTAURANT = 'MOTORWAY_RESTAURANT',
	MOTEL = 'MOTEL',
	HOTEL = 'HOTEL',
	SHOP = 'SHOP',
	DEFIBRILLATOR = 'DEFIBRILLATOR',
	CASH_MACHINE = 'CASH_MACHINE',
	PLAYGROUND = 'PLAYGROUND',
	TOLL_TERMINAL = 'TOLL_TERMINAL',
	VENDING_MACHINE = 'VENDING_MACHINE',
	INTERNET_WIRELESS = 'INTERNET_WIRELESS',
	PICNIC_FACILITIES = 'PICNIC_FACILITIES',
	KIOSK = 'KIOSK',
	LAUNDRY = 'LAUNDRY',
	CAR_WASH = 'CAR_WASH',
	WASTE_DISPOSAL = 'WASTE_DISPOSAL',
	FRESH_WATER = 'FRESH_WATER',
	OVERNIGHT_ACCOMMODATION = 'OVERNIGHT_ACCOMMODATION',
	REFUSE_BIN = 'REFUSE_BIN',
	FAX_MACHINE_OR_SERVICE = 'FAX_MACHINE_OR_SERVICE',
	PUBLIC_PHONE = 'PUBLIC_PHONE',
	DUMPING_STATION = 'DUMPING_STATION',
	COPY_MACHINE_OR_SERVICE = 'COPY_MACHINE_OR_SERVICE',
	INTERNET_TERMINAL = 'INTERNET_TERMINAL',
	DOCSTOP = 'DOCSTOP',
	OTHER = 'OTHER',
	TRUCK_REPAIR = 'TRUCK_REPAIR',
	TRUCK_WASH = 'TRUCK_WASH',
	MOTORWAY_RESTAURANT_SMALL = 'MOTORWAY_RESTAURANT_SMALL',
	FAVORITES = 'FAVORITES',
	FRIEND = 'FRIEND',
	PUBLIC = 'PUBLIC',
	DRIVERS = 'DRIVERS',
	TRAFFIC = 'TRAFFIC',
	availableForEmpty = 'AVAILABLE_FOR_EMPTY',
	availableForTruck = 'AVAILABLE_FOR_TRUCK',
	availableForOther = 'AVAILABLE_FOR_OTHER',
	feedback = 'feedback',
	mapPoint = 'mapPoint',
	salesmans = 'salesmans',
	tasks = 'tasks'
};
