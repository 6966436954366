import { LatLng } from './lat-lng.model';

export class MapBounds {
	northEast: LatLng;
	southWest: LatLng;

	constructor(northEast: LatLng, southWest: LatLng) {
		this.northEast = northEast;
		this.southWest = southWest;
	}
};
