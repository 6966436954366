import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { ChatService } from '../../services/chat.service';

@Component({
	selector: 'app-add-new-chat-channel',
	templateUrl: './add-new-chat-channel.component.html',
	styleUrls: ['./add-new-chat-channel.component.scss']
})
export class AddNewChatChannelComponent implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();
	newChannelFormGroup: FormGroup;
	loading: boolean = false;
	assignedUsers: (Employee | Driver)[] = [];

	constructor(
		public dialogRef: MatDialogRef<AddNewChatChannelComponent>,
		private fb: FormBuilder,
		private chatService: ChatService,
	) {}

	ngOnInit(): void {
		this.initNewChannelFormGroup();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	handleAddNewChatChannel = (): void => {
		this.loading = true;
		const participantsIds: string[] = this.assignedUsers
			.map((user) => user?.id ? user.id : user?.driverId)
			.filter((user): user is string => user !== undefined);

		this.subscription.add(
			this.chatService.createNewPublicChannel(this.newChannelFormGroup.controls['name'].value, participantsIds).subscribe({
				next: () => {
					this.loading = false;
					this.dialogRef.close();
				},
				error: (err) => {
					console.error(err);
					this.loading = false;
				},
			})
		)
	}

	handleAssignedUserChange = (e: { assignedUsers: (Employee | Driver)[]; currentChange: Employee | Driver}): void => {
		this.assignedUsers = e.assignedUsers;
	}

	private initNewChannelFormGroup = (): void => {
		this.newChannelFormGroup = this.fb.group({
			name: ['', Validators.required],
		});
	}
}
